import { Fragment, useState } from "react";
import { HiArrowTopRightOnSquare } from "react-icons/hi2";

import KnownBPTable from "../components/KnownBpTable";
import EbitdaTable from "../components/EbitdaTable";
import NwcTable from "../components/NwcTable";
import NetTotal from "../components/NetTotal";
import { APP_URL } from "../../../services/config";

const Form = ({ company }) => {
  return (
    <div className="space-y-8">
      <h1 className="text-xl font-semibold mb-4">Réponses au Formulaire</h1>

      <a href={`${APP_URL}/valuation/start-up/${company._id}/form`} target="_blank" rel="noreferrer" className="primary-button w-fit">
        Voir le formulaire
        <HiArrowTopRightOnSquare className="ml-2" />
      </a>

      <div className="p-2 rounded-t-lg">
        <Accordion title="Création" data={QUESTIONS.creation} company={company} />
        <Accordion title="Humain - Équipe" data={QUESTIONS.human_team} company={company} />
        <Accordion title="Humain - Support" data={QUESTIONS.human_support} company={company} />
        <Accordion title="Offre - Produit" data={QUESTIONS.offer_product} company={company} />
        <Accordion title="Offre - Concurrence" data={QUESTIONS.offer_competition} company={company} />
        <Accordion title="Offre - Commercialisation" data={QUESTIONS.offer_launch} company={company} />
        <Accordion title="Offre - Monétisation" data={QUESTIONS.offer_monetization} company={company} />
        <Accordion title="Marché - Positionnement" data={QUESTIONS.market_position} company={company} />
        <Accordion title="Marché - Caractéristiques" data={QUESTIONS.market_characteristics} company={company} />
        <Accordion title="Marché - Concurrents" data={QUESTIONS.market_competitors} company={company} />
        <Accordion title="Stratégie - Partenariats" data={QUESTIONS.strategy_partnership} company={company} />
        <Accordion title="Stratégie - IP" data={QUESTIONS.strategy_ip} company={company} />
        <Accordion title="Stratégie - Communication" data={QUESTIONS.strategy_communication} company={company} />
        <Accordion title="Stratégie - Croissance" data={QUESTIONS.strategy_growth} company={company} />
        <Accordion title="Innovation - Etat de l'art" data={QUESTIONS.innovation_state_of_the_art} company={company} />
        <Accordion title="Innovation - RnD" data={QUESTIONS.innovation_rnd} company={company} />
        <AccordionFinance title="Finance" company={company} />
        <Accordion title="Impact - RSE" data={QUESTIONS.impact_cse} company={company} />
        <Accordion title="Impact - Potentiel" data={QUESTIONS.impact_potential} company={company} />
      </div>
    </div>
  );
};

const AccordionFinance = ({ title, company }) => {
  const [accordionOpen, setAccordionOpen] = useState(true);
  return (
    <div className="mb-2">
      <button
        onClick={() => setAccordionOpen(!accordionOpen)}
        className={`mb-1 p-3 border border-secondary hover:border-black transition-all rounded-lg  flex justify-between items-center w-full ${accordionOpen && "border-black"}`}>
        <h2 className="text-l font-semibold">{title}</h2>
        <svg className="fill-grey-500 shrink-0 ml-8" width="16" height="16" xmlns="http://www.w3.org/2000/svg">
          <rect y="7" width="16" height="2" rx="1" className={`transform origin-center transition duration-200 ease-out ${accordionOpen && "!rotate-180"}`} />
          <rect y="7" width="16" height="2" rx="1" className={`transform origin-center rotate-90 transition duration-200 ease-out ${accordionOpen && "!rotate-180"}`} />
        </svg>
      </button>
      <div
        className={`grid overflow-hidden transition-all duration-300 ease-in-out text-slate-600 text-sm ${
          accordionOpen ? "grid-rows-[1fr] opacity-100" : "grid-rows-[0fr] opacity-0"
        }`}>
        <div className="pl-3 overflow-hidden">
          <Accordion title="Situation" data={QUESTIONS.finance.situation} company={company} />
          <Accordion title="Shareholders" data={QUESTIONS.finance.shareholders} company={company} />
          <Accordion title="Funding" data={QUESTIONS.finance.funding} company={company} />
          <Accordion title="Review" data={QUESTIONS.finance.review} company={company} />
          <Accordion title="Business Plan" data={QUESTIONS.finance.businessPlan} company={company} />
        </div>
      </div>
    </div>
  );
};

const Accordion = ({ title, data, company }) => {
  const [accordionOpen, setAccordionOpen] = useState(true);
  const years = Array.from(Array(11).keys()).map((i) => company.year_4 - 4 + i);
  const computedValues = {
    net_operating_income: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    net_working_capital: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    free_cash_flow: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    actualized_free_cash_flow: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  };

  if (data) {
    computedValues.net_operating_income = years.map((_, index) => (data[`ebitda_${index}`] || 0) - (data[`dna_${index}`] || 0) - (data[`other_charges_${index}`] || 0));
    computedValues.net_working_capital = years.map((_, index) => (index === 0 ? 0 : (data[`working_capital_${index}`] || 0) - (data[`working_capital_${index - 1}`] || 0)));
    computedValues.free_cash_flow = years.map(
      (_, index) => (data[`ebitda_${index}`] || 0) - (data[`taxes_${index}`] || 0) - (data[`investments_${index}`] || 0) - (data[`working_capital_${index}`] || 0),
    );
    computedValues.actualized_free_cash_flow = years.map((_, index) => (data[`free_cash_flow_${index}`] || 0) / Math.pow(1 + (data[`discount_rate`] || 0), index + 1));
  }

  const renderSwitch = (param) => {
    switch (param) {
      case "BP Connu":
        return <KnownBPTable values={company} computedValues={computedValues} />;
      case "Calcul du flux de trésorerie disponible avec EBITDA":
        return <EbitdaTable values={company} computedValues={computedValues} />;
      case "Calcul du BFR":
        return <NwcTable values={company} computedValues={computedValues} />;
      case "Calcul du flux de trésorerie disponible avec le total net":
        return <NetTotal values={company} computedValues={computedValues} />;
      default:
        return;
    }
  };

  const totalDistribution =
    (company?.team_distribution_marketing || 0) +
    (company?.team_distribution_sales || 0) +
    (company?.team_distribution_engineering || 0) +
    (company?.team_distribution_dev || 0) +
    (company?.team_distribution_product || 0) +
    (company?.team_distribution_finance || 0);

  const totalFunds =
    (company?.funds_usage_recruitment || 0) +
    (company?.funds_usage_operational_costs || 0) +
    (company?.funds_usage_rnd || 0) +
    (company?.funds_usage_communication || 0) +
    (company?.funds_usage_managers_salary || 0) +
    (company?.funds_usage_other || 0);

  return (
    <div className={`mb-2 ${title === "Situation" && "pt-2"}`}>
      <button
        onClick={() => setAccordionOpen(!accordionOpen)}
        className={`mb-1 p-3 border border-secondary hover:border-black transition-all rounded-lg  flex justify-between items-center w-full ${accordionOpen && "border-black"}`}>
        <h2 className="text-l font-semibold">{title}</h2>
        <svg className="fill-grey-500 shrink-0 ml-8" width="16" height="16" xmlns="http://www.w3.org/2000/svg">
          <rect y="7" width="16" height="2" rx="1" className={`transform origin-center transition duration-200 ease-out ${accordionOpen && "!rotate-180"}`} />
          <rect y="7" width="16" height="2" rx="1" className={`transform origin-center rotate-90 transition duration-200 ease-out ${accordionOpen && "!rotate-180"}`} />
        </svg>
      </button>
      <div
        className={`grid overflow-hidden transition-all duration-300 ease-in-out text-slate-600 text-sm ${
          accordionOpen ? "grid-rows-[1fr] opacity-100" : "grid-rows-[0fr] opacity-0"
        }`}>
        <div className="pl-3 overflow-hidden">
          {Object.keys(data).map((key) => {
            if (title === "Business Plan") {
              return (
                <Fragment key={key}>
                  <div className="py-2 flex flex-col gap-1">
                    <label className="text-black text-sm">{data[key]}</label>
                    {renderSwitch(data[key])}
                  </div>
                  <hr class="my-1 mx-4 h-px border-t-0 bg-neutral-200" />
                </Fragment>
              );
            }
            if (key.includes("-")) {
              const [parent, child] = key.split("-");
              if (child === "label") {
                return (
                  <div className="py-2">
                    <label className="text-black text-sm">{data[key]}</label>
                  </div>
                );
              } else if (child === "total") {
                return parent === "team" ? (
                  <Fragment key={key}>
                    <div className="pl-5 grid grid-cols-2 gap-4">
                      <label className="text-black text-sm">{data[key]}</label>
                      <p className="font-semibold">{totalDistribution}</p>
                    </div>
                    <hr class="my-1 mx-4 h-px border-t-0 bg-neutral-200" />
                  </Fragment>
                ) : (
                  <Fragment key={key}>
                    <div className="pl-5 grid grid-cols-2 gap-4">
                      <label className="text-black text-sm">{data[key]}</label>
                      <p className="font-semibold">{totalFunds}</p>
                    </div>
                    <hr class="my-1 mx-4 h-px border-t-0 bg-neutral-200" />
                  </Fragment>
                );
              } else {
                return (
                  <Fragment key={key}>
                    <div className="pl-5 grid grid-cols-2 gap-4">
                      <label className="text-black text-sm">{data[key]}</label>
                      <p className="font-semibold">{company[child] ? company[child] : "-"}</p>
                    </div>
                    <hr class="my-1 mx-4 h-px border-t-0 bg-neutral-200" />
                  </Fragment>
                );
              }
            } else {
              if (key.includes(".")) {
                const [parent, child] = key.split(".");
                return (
                  <Fragment key={key}>
                    <div className="grid grid-cols-2 gap-4 ">
                      <label className="text-black text-sm">{data[key]}</label>
                      <p className="font-semibold">{company && company[parent] && company[parent][child] ? company[parent][child] : "-"}</p>
                    </div>
                    <hr class="my-1 mx-4 h-px border-t-0 bg-neutral-200" />
                  </Fragment>
                );
              }
              return (
                <Fragment key={key}>
                  <div className="grid grid-cols-2 gap-4 ">
                    <label className="text-black text-sm">{data[key]}</label>
                    {typeof company[key] === "boolean" ? (
                      company[key] ? (
                        <p className="font-semibold">Oui</p>
                      ) : (
                        <p className="font-semibold">Non</p>
                      )
                    ) : (
                      <p className="font-semibold">{typeof company[key] === "object" ? (company[key]?.length > 0 ? company[key].join(", ") : "-") : company[key] ?? "-"}</p>
                    )}
                  </div>
                  <hr class="my-1 mx-4 h-px border-t-0 bg-neutral-200" />
                </Fragment>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

const QUESTIONS = {
  creation: {
    company_name: "Nom de l'entité / de l'entreprise",
    //elevator_pitch: "Quel est votre « Elevator Pitch » ?",
    //email: "Adresse e-mail de contact",
    //phone_number: "Fournir votre numéro de téléphone",
    "metadata.forme_juridique": "Quel est le statut juridique de votre entreprise ?",
    country: "Dans quel pays le siège social de votre entreprise est-il situé ?",
    city: "Dans quelle ville votre entreprise est-elle située ?",
    main_activity_sector: "Quel est votre secteur d'activité principal ?",
    //stage_of_maturity_of_start_up: "Quel est le stade de maturité de votre startup ?",
    //stage_of_maturity_of_product: "Quel est le stade de maturité de votre produit ?",
    "metadata.date_creation": "Quand l'entreprise a-t-elle été créée ou le sera-t-elle ?",
    company_siren: "Numéro d'identification national de votre entreprise",
    //logo: "Insérez votre logo ici",
    //why_evaluate: "Pourquoi souhaitez-vous évaluer votre startup ?",
    //last_turnover: "Dernier chiffre d'affaires connu (chiffre d'affaires de l'année écoulée le cas échéant, sinon celui en cours)",
  },
  human_team: {
    founder_count: "Combien de personnes ont fondé l'entreprise ?",
    employee_count: "Combien d'employés à temps plein avez-vous actuellement ?",
    trainee_count: "Combien de stagiaires/internes employez-vous actuellement ?",
    freelancers_count: "Combien de freelancers travaillez-vous avec ?",
    "team-label": "What is the distribution of the internal team?",
    "team-team_distribution_marketing": "Marketing - Communication",
    "team-team_distribution_sales": "Ventes - Développement commercial",
    "team-team_distribution_engineering": "Ingénierie (matériel, expertise technique)",
    "team-team_distribution_dev": "Développement (frontal / dorsal, programmation)",
    "team-team_distribution_product": "Production (produit)",
    "team-team_distribution_finance": "Opérations et finances internes",
    "team-total": "total",
  },
  human_support: {
    expert_backed: "La startup est-elle soutenue par un conseil d'experts sur les questions stratégiques ? (hors comité scientifique)",
    experts_count: "Combien d'experts font partie du conseil consultatif ?",
    sci_committee_support: "La startup est-elle soutenue par un comité scientifique travaillant sur des questions scientifiques et/ou technologiques ?",
    scientific_count: "Combien de scientifiques font partie de ce comité scientifique ?",
    legal_assistance: "Votre entreprise dispose-t-elle ou est-elle assistée par un expert juridique ?",
    accountant_support: "Votre entreprise dispose-t-elle ou est-elle assistée par un comptable ?",
  },

  offer_product: {
    maturity_stage: "Quel est le stade de maturité de votre entreprise ?",
    product_stage: "Quel est le stade de maturité de votre produit ?",
    stage_of_concept: "Stade de maturité du concept",
    market_presence: "Votre offre principale est-elle déjà sur le marché ?",
    time_to_market: "Dans combien de temps la mise sur le marché sera-t-elle réalisée ?",
    spending_left_until_market: "Quel est le montant estimé des dépenses nécessaires restantes avant la mise sur le marché ?",
    stage_of_adoption: "Indiquez le stade d'adoption de votre offre",
    market_challenges: "Quels sont les problèmes et les défis dans votre marché que vous essayez de résoudre ?",
    problem_solution: "Quelle est votre solution à ces problèmes ?",
    value_proposation: "Quelle est la proposition de valeur de votre offre ?",
  },
  offer_competition: {
    business_costs_position: "Comment vous positionnez-vous par rapport à l'ensemble des solutions concurrentes, y compris indirectes, quant aux coûts pour vos clients ?",
    cost_position: "Comment vous positionnez-vous par rapport à vos concurrents proches, quant aux coûts pour vos clients ?",
    differentiation_position: "Comment vous positionnez-vous par rapport à l'ensemble des solutions concurrentes, en matière de différenciation ?",
    quality_position: "Comment vous positionnez-vous par rapport à l'ensemble des solutions concurrentes, y compris indirectes, quant à la qualité de produit ou service ?",
    close_competitor_quality: "Comment vous positionnez-vous par rapport à vos concurrents proches, quant à la qualité de produit ou service ?",
  },
  offer_launch: {
    main_clients: "Indiquez les segments clients ciblés",
    has_customer_reference: "Avez-vous des références clients significatives ?",
    reference_names: "Indiquez les noms de ces références",
    main_business_model: "Quel est votre principal type de modèle d'affaires en place ?",
    business_models: "Renseignez le(s) type(s) de modèle d'affaires en place",
    offer_dev_stage: "Identifiez le stade de développement de votre offre",
    user_testing_phase: "Avez-vous effectué une phase de test utilisateur lors de la définition de votre offre ? ",
    user_testing_types: "Quels types de tests utilisateur avez-vous effectués ?",
  },
  offer_monetization: {
    recurring_revenue: "Votre modèle d'affaires permet-il de générer des revenus récurrents ? ",
    is_mrr_major_income: "Ces revenus récurrents constituent-ils l'essentiel de vos revenus ?",
    mrr: "Indiquez le montant du revenu mensuel récurrent (MRR) à ce jour",
    current_churn_rate: "Indiquez votre taux de désabonnement actuel",
    clv_knowledge: "Connaissez-vous votre CLV ? ",
    clv: "Quel est votre CLV ?",
    cac_knowledge: "Connaissez-vous votre CAC ? ",
    cac: "Quel est votre CAC ?",
    nps_knowledge: "Connaissez-vous votre NPS ? ",
    nps: "Quel est votre NPS ?",
    revenue_sources: "Indiquez le ou les type(s) de source(s) de revenus",
    scalability_assessment: "Votre modèle économique a-t-il une bonne scalabilité ?",
  },
  market_position: {
    current_area: "Indiquez votre ou vos zone(s) d'activités actuelle(s)",
    target_area: "Indiquez votre ou vos zone(s) d'activités visée(s) à horizon 5 ans",
    company_sector: "Quel est votre secteur d'activité principal ?",
    market_size: "Quelle est la taille de votre marché ?",
    sam: "Quel est votre SAM (Serviceable Addressable Market) ?",
    current_position: "Quelle est votre position actuelle dans votre segment de marché ?",
    expected_position_in_5_years: "Quelle position pouvez-vous raisonnablement espérer occuper dans 5 ans dans votre segment de marché ?",
  },
  market_characteristics: {
    entry_investment_requirements: "L'entrée sur votre marché nécessite-t-elle des investissements matériels significatifs (ressources de fabrication, infrastructure, etc.) ?",
    entry_investment_amount: "Quel est le montant des investissements matériels déjà réalisés pour entrer sur le marché ?",
    market_maturity: "Quel est le degré de maturité de votre marché ?",
    has_product_authorization_needs: "Votre produit nécessite-t-il des autorisations spécifiques avant d'entrer sur le marché ?",
    authorities: "Indiquez vos autorités de réglementation",
    obtained_authorizations: "Avez-vous déjà obtenu ces autorisations ?",
    refusal_rate: "Quel est le taux de refus de ces autorisations ?",
    authorization_timeline: "Combien de temps faudra-t-il encore pour les obtenir ?",
    network_effects_presence: "Êtes-vous dans un segment de marché où les effets de réseau sont significatifs ?",
    special_features_demand_intensity: "Quelle est l'intensité de la demande pour des fonctionnalités spécifiques dans votre segment de marché ?",
    market_fit: "Votre offre s'intègre-t-elle dans un marché existant ou cherche-t-elle à créer un nouveau marché ?",
  },
  market_competitors: {
    competitors_size: "Quelle est la taille des concurrents ?",
    // competitor_name: "Quel est le montant des investissements matériels déjà réalisés pour entrer sur le marché ?",
    target_customers_costs_multiple_use:
      "Vos clients cibles seraient-ils confrontés à des coûts ou à des problèmes s'ils utilisaient plusieurs solutions concurrentes en même temps ?",
    is_easy_switch: "Est-il facile pour vos clients de passer d'une solution à une autre solution concurrente ?",
    competitors_pricing_level: "Quel est le niveau de concurrence en termes de prix dans votre marché total (y compris les concurrents indirects) ?",
    strong_market_pricing: "Quelle est l'intensité de la concurrence en termes de prix dans votre segment de marché (concurrents proches) ?",
    is_competitive_env_favorable: "L'environnement concurrentiel est-il favorable ?",
  },
  strategy_partnership: {
    support_structure: "Êtes-vous (ou avez-vous été) incubé dans une structure de soutien (incubateur, accélérateur ou programme d'innovation) ? ",
    has_partnership: "Avez-vous développé des partenariats commerciaux avec d'autres entités ?",
    partners: "Précisez les noms de ces partenaires et la nature de votre collaboration",
  },
  strategy_ip: {
    has_intellectual_properties: "Avez-vous une propriété intellectuelle protégée ? (marque, modèle, design)  ",
    intellectual_properties: "Décrivez cette propriété intellectuelle",
  },
  strategy_communication: {
    acquisition_channels: "Indiquez les types de canaux d'acquisition utilisés",
    social_networks: "Indiquez votre présence sur les réseaux sociaux",
    medias: "Indiquez votre présence médiatique",
    website_link: "Website link : ",
    linkedin_link: "Linkedin page link :",
    instagram_link: "Instagram page :",
    twitter_link: "Twitter page :",
    facebook_link: "Facebook page :",
    angelList_link: "AngelList link : ",
  },
  strategy_growth: {
    crucial_activities_internalised: "Les activités cruciales sont-elles internalisées ?",
    exit_opportunities: "Quelles sont les opportunités de sortie que vous envisagez ?",
    expected_exit_time: "Quel est le délai de sortie prévu ?",
  },
  innovation_state_of_the_art: {
    type_of_innovation: "Quel est le type de votre innovation ?",
    technology_origin: "Votre solution utilise-t-elle des technologies existantes ou s'agit-il de la création d'une nouvelle technologie ?",
    tech_maturity_stage: "À quel stade de maturité se trouve votre nouvelle technologie ? (TRL)",
    is_product_based_on_others_tech_innovation: "Votre offre est-elle basée sur des technologies innovantes développées par d'autres acteurs ?",
    others_tech_maturity_stage: "À quel point ces technologies sont-elles matures ?",
  },
  innovation_rnd: {
    amount_spent_rnd: "Indiquez le montant total dépensé pour la recherche et/ou le développement pour la création et l'amélioration continue de votre offre",
    is_solution_patentable: "La solution développée est-elle brevetable ? ",
    patents_registered: "Combien de brevets technologiques autour de votre innovation avez-vous enregistrés à ce jour ?",
    patents_description: "Veuillez décrire les brevets qui ont été déposés",
    has_rnd_partnership: "vez-vous établi des partenariats de R&D ?",
    RnD_partnerships_count: "Indiquez le nombre de partenariats signés pour la R&D",
    partnerships_details: "Indiquez le nom de ces partenaires et la nature de votre collaboration",
    is_collection_personal_data: "Collectez-vous des données personnelles lors de vos activités ? ",
    is_collection_personal_data_proctected: "Avez-vous mis en place des procédures de protection des données personnelles (déclaration CNIL, conformité GDPR, ...) ? ",
    tech_innovation_award: "Avez-vous reçu un prix pour votre innovation technologique ?",
    obtained_prizes: "Précisez les prix que vous avez obtenus :",
  },
  finance: {
    situation: {
      is_profitable: "Votre entreprise est-elle déjà rentable (bénéfice net positif) ?",
      cash_flow: "Quel est votre solde de trésorerie actuel ?",
      current_monthly_cash_burn: "Quel est votre taux de consommation de trésorerie mensuel actuel ?",
      net_income_3: "Quel était le bénéfice net de l'année fiscale précédente ?",
    },
    shareholders: {
      shareholders_count: "Combien d'actionnaires compte l'entreprise ?",
    },
    businessPlan: {
      knownBpTable: "BP Connu",
      ebitdaTable: "Calcul du flux de trésorerie disponible avec EBITDA",
      nwcTable: "Calcul du BFR",
      netTotalTable: "Calcul du flux de trésorerie disponible avec le total net",
    },
    funding: {
      has_received_non_repavable_grants: "Avez-vous reçu des subventions non remboursables depuis la création de votre entreprise ?",
      total_amount_subsidies_received: "Quel est le montant total des subventions que vous avez reçues depuis la création de votre entreprise (en €) ?",
      grants_description: "Décrivez les subventions reçues",
      is_looking_for_funds: "Êtes-vous à la recherche de fonds ?",
      funds_needed: "Quel est le montant des fonds nécessaires pour couvrir les 18 prochains mois (en €) ?",
      dilution_target: "Indiquez le niveau de dilution ciblé pour cette opération (en %)",
      // funds_usage_recruitment: "Calcul du flux de trésorerie disponible avec le total net",
      fundraising_finalized_at: "Quand prévoyez-vous de finaliser la collecte de fonds ?",
      share_percentage_divest: "Quel pourcentage d'actions êtes-vous prêt à céder pour votre collecte de fonds ? (En %)",
      has_raised_funds_in_past: "Avez-vous déjà levé des fonds auprès de professionnels par le passé ?",
      // fundrasers: "Calcul du flux de trésorerie disponible avec le total net",
      prior_fundraising_experience: "Les dirigeants de l'entreprise ont-ils déjà levé des fonds lors d'expériences antérieures ? ",
      has_been_valued: "Avez-vous déjà été évalué ? ",
      last_valuation: "Quelle était la dernière évaluation ?",
      last_valuation_date: "Quand cette évaluation a-t-elle été réalisée ? ",
      last_valuation_context: "Quel était le contexte de cette évaluation et qui l'a réalisée ?",
    },
    review: {
      intangible_assets: "Actifs incorporels pour la dernière année connue",
      tangible_fixed: "Actifs corporels fixes pour la dernière année connue",
      stocks: "Stocks (marchandises, matières premières, produits finis) pour la dernière année connue",
      customer_receivables: "Créances clients pour la dernière année connue",
      equity_capital: "Capital social pour la dernière année connue",
      amout_of_shareholders: "Quel est le montant des capitaux propres des actionnaires ?",
      operating_liabilities: "Dettes d'exploitation pour la dernière année connue",
      debt: "Dettes financières pour la dernière année connue",
    },
    business_plan: {
      knownBpTable: "BP Connu",
      ebitdaTable: "Calcul du flux de trésorerie disponible avec EBITDA",
      nwcTable: "Calcul du BFR",
      netTotalTable: "Calcul du flux de trésorerie disponible avec le total net",
    },
  },
  impact_cse: {
    expertise_in_sustainable_development: "La direction a-t-elle une expertise en développement durable (dans un poste précédent par exemple) ?",
    founders_CR_expertise: "Veuillez fournir des détails sur l'expertise des fondateurs en matière de responsabilité d'entreprise",
    is_sustainable_considered_from_creation: "Le thème de la responsabilité sociale a-t-il été pris en compte dès le début de la création du modèle d'entreprise ? ",
    has_sustainable_action_made: "Avez-vous mené des actions spécifiques en matière de responsabilité sociale depuis la création de votre entreprise ? ",
    actions_taken_details: "Veuillez fournir des détails sur les actions entreprises.",
    environment_kpi: "Votre entreprise a-t-elle des KPI orientés vers les résultats sociaux et environnementaux ?",
    environment_kpi_details: "Veuillez fournir des détails sur les indicateurs en place",
    environment_criteria: "Intégrez-vous des critères environnementaux et de gouvernance sociale (ESG) dans votre stratégie de développement ?",
    environment_criteria_details: "Veuillez décrire les critères ESG en question.",
  },
  impact_potential: {
    population_covered_amount: "La direction a-t-elle une expertise en développement durable (dans un poste précédent par exemple) ?",
    population_covered_details: "Veuillez fournir des détails sur l'expertise des fondateurs en matière de responsabilité d'entreprise",
    completeness_degree: "Le thème de la responsabilité sociale a-t-il été pris en compte dès le début de la création du modèle d'entreprise ? ",
    certainty_degree: "Avez-vous mené des actions spécifiques en matière de responsabilité sociale depuis la création de votre entreprise ? ",
    expected_benefits_nature: "Veuillez fournir des détails sur les actions entreprises.",
    benefiting_areas: "Votre entreprise a-t-elle des KPI orientés vers les résultats sociaux et environnementaux ?",
    benefiting_individuals: "Veuillez fournir des détails sur les indicateurs en place",
    integrated_extendt: "Intégrez-vous des critères environnementaux et de gouvernance sociale (ESG) dans votre stratégie de développement ?",
    benefiting_goals: "Veuillez décrire les critères ESG en question.",
  },

  // English Version

  // creation: {
  //   company_name: "Name of the entity / company",
  //   elevator_pitch: "What is your “Elevator Pitch”?",
  //   email: "Contact email",
  //   phone_number: "Provide your phone number",
  //   company_legal_status: "What is your company's legal status?",
  //   head_office_country: "In which country is the head office of your company located?",
  //   city: "What city is your company located in?",
  //   activity_sector: "What is you main activity sector?",
  //   stage_of_maturity_of_start_up: "What is the stage of maturity of your startup?",
  //   stage_of_maturity_of_product: "What is the stage of maturity of your product?",
  //   company_creation_time: "When was or will the company be created?",
  //   national_id_of_company: "National identification number of your company",
  //   logo: "Insert your logo here",
  //   why_evaluate: "Why do you want to evaluate your startup?",
  //   last_turnover: "Last turnover known (Turnover of the past year if relevant, current one otherwise)",
  // },
  // human: {
  //   founder_count: "How many people founded the company?",
  //   employee_count: "How many full-time employees do you currently have?",
  //   trainee_count: "How many trainees/interns do you currently employ?",
  //   freelancers_count: "How many freelancers do you work with?",
  //   "team.label": "What is the distribution of the internal team?",
  //   "team.team_distribution_marketing": "Marketing - Communication",
  //   "team.team_distribution_sales": "Sales - Business Development",
  //   "team.team_distribution_engineering": "Engineering (hardware, technical expertise)",
  //   "team.team_distribution_dev": "Front / back development (code, programming)",
  //   "team.team_distribution_product": "Production (product)",
  //   "team.team_distribution_finance": "Operations and internal finance",
  //   "team.total": "total",
  // },
  // offer: {
  //   main_clients: "Who are your main clients? ",
  //   any_significant_customer_reference: "Do you have any significant customer references?",
  //   businesses_model: "Identify the type(s) of business model(s) in place",
  //   business_position_in_terms_of_costs_for_your_customers:
  //     "In comparison with all the competing solutions, including indirect competitors, how do you position your business in terms of costs for your customers?",
  //   recurring_revenues_major_part_of_your_income: "Do these recurring revenues constitute the major part of your income?",
  //   indicate_the_amount_of_monthly_revenue: "Indicate the amount of monthly recurring revenue (MRR) to date",
  // },
  // market: {
  //   current_area: "Enter your current activity area(s)",
  //   target_area_for_next_5_years: "Enter the target activity area(s) for the next 5 years ",
  //   main_activity_sector: "What is you main activity sector?",
  //   how_big_the_competitors: "How big are the competitors? ",
  //   how_mature_is_ur_market: "How mature is your market?",
  //   does_ur_product_require_authorisations_to_enter_the_market: "Does your product require any specific authorisations before entering the market?",
  //   target_customers_face_costs_: "Would your target customers face costs or issues if they used multiple competing solutions at the same time?",
  //   is_it_easy_for_customer_to_switch_from_one_to_other_competing_solution: "Is it easy for your customers to switch from one solution to another competing solution?",
  //   price_of_total_competing_leve_in_your_market: "What is the price competition level in your total market (including indirect competitors)?",
  //   how_strong_is_the_price_competing: "How strong is the price competition in your market segment (close competitors)?",
  // },
  // strategy: {
  //   have_you_developed_partnerships_with_other_entities: "Have you developed business partnerships with other entities?",
  //   name_of_these_partners: "Specify the names of these partners and the nature of your collaboration",
  //   name_of_these_partners_and_coll: "Indicate the name of these partners and the nature of your collaboration",
  //   do_u_have_protected_intellectual_property: "Do you have a protected intellectual property? (brand, model, design.)",
  //   describe_intellectual_property: "Describe this intellectual property",
  //   indicate_types_of_channel: "Indicate the types of acquisition channels used",
  //   indicate_social_network: "Indicate your social network presence",
  //   indicate_media_persence: "Indicate your media presence",
  //   are_u_crucial_activities_internalised: "Are crucial activities internalised?",
  //   any_RandD_partnership: "Have you established any R&D partnerships?",
  // },
  // innovation: {
  //   is_ur_product_based_on_tech_or_innovation: "Is your product based on a technological or usage innovation",
  //   does_ur_solution_use_exsiting_tech_or_create_new: "Does your solution use existing technologies or is it the creation of a new technology?",
  //   stage_maturity_of_your_new_tech: "What is the stage of maturity of your new technology? (TRL)",
  //   is_your_offer_based_on_innovative_tech: "Is your offer based on innovative technologies developed by other players?",
  //   how_mature_are_these_technologies: "How mature are these technologies?",
  //   enter_the_total_amount_spent_on_research_development:
  //     "Enter the total amount spent on research and/or development for the creation and continuous improvement of your offering",
  //   is_the_developed_solution_patentable: "Is the developed solution patentable?",
  //   personal_data: "Do you collect personal data during your activities?",
  //   personal_data_protection_procedures_set_up: "Have you set up personal data protection procedures (CNIL declaration, GDPR compliance, ...)?",
  // },
  // finance: {
  //   situation: {
  //     is_profitable: "Is your business already profitable (positive net income)? ",
  //     cash_flow: "What is your current cash balance? ",
  //     current_monthly_cash_burn: "What is your current monthly cash burn rate? ",
  //     have_you_recevied_non_repavable_grants: "Have you received an non-repavable grants since you started vour business?",
  //     total_amount_of_subsidies_you_have_received: "What is the total amount of subsidies you have received since your company was created (in €)?",
  //     are_u_looking_for_funds: "Are you looking for funds?",
  //     amounts_of_funds_need_to_cover_for_next_18_months: "What is the amount of funds needed to cover the next 18 months (in €)? ",
  //     targeted_dilution_level: "Indicate the targeted dilution level for this operation (in %)",
  //     "funds.label": "What is the intended use of these funds?",
  //     "funds.funds_usage_recruitment": "Recruit",
  //     "funds.funds_usage_operational_costs": "Support operational costs",
  //     "funds.funds_usage_rnd": "Conducting R&D",
  //     "funds.funds_usage_communication": "Performing communication operations",
  //     "funds.funds_usage_managers_salary": "Remunerate the manager(S)",
  //     "funds.funds_usage_other": "Other uses",
  //     "funds.total": "total",
  //     fundraising_finalized_at: "When do you expect to finalize the fundraising?",
  //     percentage_of_share_you_are_willing_to_diverse_for_fundraising: "What percentage of shares are you willing to divest for your ? (In %)",
  //     has_raised_funds_in_past: "Have you raised funds from professionals in the past?",
  //     have_you_ever_been_valued: "Have you ever been valued?",
  //     last_valuation: "What was the last valuation? ",
  //     when_was_last_valuation: "When was this valuation made? ",
  //     context_and_who_conducted_it: "What was the context for this valuation and who conducted it?",
  //     net_income_last_year: "What was the net income for the last fiscal year? ",
  //     intangible_assets: "Intangible assets for the last known year",
  //     tangible_fixed: "Tangible fixed assets for the last known year",
  //     stocks: "Stocks (merchandise, raw materials, finished products) for the last known year",
  //     customer_receivables: "Customer receivables for the last known year",
  //     equity_capital: "Equity capital for the last known year",
  //     amout_of_shareholders: "What is the amount of shareholders' equity?",
  //     operating_liabilities: "Operating liabilities for the last known year",
  //     financial_debt: "Financial debts for the last known year",
  //   },
  //   businessPlan: {},
  // },
  // impact: {
  //   does_management_have_any_expertise_in_sustainable_development: "Does the management team have any expertise in sustainable development (in a previous position for instance)?",
  //   was_theme_of_social_responsibility_takeen_into_account: "Was the theme of social responsibility taken into account from the beginning when creating the business model?",
  //   have_you_carried_out_any_specific_action_in_terms_of_social_responsibility:
  //     "Have you carried out any specific actions in terms of social responsibility since the creation of your company?",
  //   please_provide_details_of_the_indicators_in_place: "Please provide details of the indicators in place",
  //   do_u_integrate_env_and_social_governance_criteria_into_ur_dev_strategy: "Do you integrate environmental and social governance (ESG) criteria into your development strategy?",
  //   describe_ESG_criteria: "Please describe the ESG criteria in question. ",
  //   does_your_offer_directly_address_issues_related_to_sustainable_development: "Does your offer directly address issues related to sustainable development?",
  //   how_much_of_the_population_can_your_solution_cover: "How much of the population can your solution cover?",
  //   provide_details_on_the_proportion_of_the_population_addressed: "Please provide details on the proportion of the population addressed.",
  //   your_solution_according_to_the_problem_addressed: "Define the degree of completeness of your solution according to the problem addressed.",
  //   indicate_the_degree_of_certainty_about_the_benefits_of_your_solution: "Indicate the degree of certainty about the benefits of your solution.",
  //   the_nature_of_the_expected_benefits: "Specify the nature of the expected benefits.",
  //   select_intervention_benefiting_from_your_solution: "Among the following areas of intervention, select the one or those benefiting from your solution. ",
  //   select_individuals_benefiting_from_your_solution: "Among the following groups of individuals, select the one or ones benefiting from your solution.",
  //   which_extented_do_u_integrate_sustainable_development: "To which extent do you integrate sustainable development objectives into your development strategy? ",
  //   select_ustainable_development_benefiting_from_your_solution: "Among the 17 sustainable development goals, select the one or ones that benefit your solution.",
  // },

  //Other fields

  //   executive: "Executive Profiles",
  //   leader: "What is the name of the leader ?",
  //   name: "Name",
  //   is_leader_founder: "Is this leader one of the founders of the company?",
  //   title: "Title",
  //   commitment: "What is the time commitment of this leader in the company?",
  //   type_of_training: "What type of training did the leader receive?",
  //   leader_diploma: "What is the leader's level of diploma?",
  //   institutions: "Specify the educational institutions (higher education)",
  //   prof_exp: "How many years of professional experience does this leader have?",
  //   industry_exp: "What are the experiences of this leader in the industry in which your startup operates?",
  //   managerial_skills: "What are the managerial skills acquired during previous experiences for this manager?",
  //   technical_experience: "What are the technical experiences and skills of this leader?",
  //   sales_marketing_skills: "What sales/marketing skills has this manager acquired through previous experience ? (excluding finance)",
  //   financial: "What are the financial skills acquired during previous experiences for this manager?",
  //   entrepreneurial: "What are the entrepreneurial skills acquired during previous experiences for this manager?",
  //   linkedin_profile: "What is the Linkedin link for this leader's profile?",
  //   is_startup_backup_by_board_expert: "Is the startup backed by a board of experts on strategic issues? (excluding a scientific committee)",
  //   how_many_experts: "How many experts are part of the advisory board?",
  //   is_startup_backup_by_scientific_committee: "Is the startup backed by a scientific committee working on scientific and/or technological issues?",
  //   how_many_scientific: "How many scientists are in this scientific committee?",
  //   is_assisted_by_legal_expert: "Does your company have or is assisted by a legal expert?",
  //   is_assisted_by_an_accountant: "Does your company have or is assisted by an accountant?",
  //   stage_of_concept: "Maturity stage of the concept",
  //   stage_of_adoption: "Identify the stage of adoption of your offer",
  //   is_main_already_on_market: "Is your main offer already on the market?",
  //   problems_on_the_market: "What are the problems and challenges within your market that you're trying to address?",
  //   solution_to_these_problems: "What is your solution to these problems?",
  //   value_proposation: "What is the value proposition of your offer?",
  //   cost_for_customers: "How do you position your business compared to your competitors in terms of costs for your customers?",
  //   business_position_compared_to_all_competing_solutions: "In terms of differentiation, how do you position your business compared to all competing solutions?",
  //   business_position_compared_to_all_competing_solutions_in_terms_of_service_products:
  //     "In terms of product or service quality, how do you position your business compared to all competing solutions, including indirect ones?",
  //   business_position_compared_to_close_competing_solutions_in_terms_of_service_products:
  //     "In terms of product or service quality, how do you position your business compared to your close competitors?",

  //   any_significant_customer_reference: "Do you have any significant customer references?",
  //   indicate_references: "Indicate these references names ",
  //   main_business_model: "What is your main business model?",
  //   development_stage_of_offer: "Identify the development stage of your offer",
  //   user_testing_phase: "Did you go through a user testing phase when defining your offer?",
  //   what_type_of_user_testing: "What types of user testing have you done?",
  //   does_ur_biz_model_generate_recurring_revenues: "Does your business model generate recurring revenues?",
  //   indicate_current_churn: "Indicate your current churn rate",
  //   do_u_know_ur_CLV: "Do you know your CLV?",
  //   what_is_ur_CLV: "What is your CLV?",
  //   do_u_know_ur_CAC: "Do you know your CAC?",
  //   what_is_ur_CAC: "What is your CAC?",
  //   do_u_know_ur_NPS: "Do you know your NPS?",
  //   type_of_revenue_source: "Indicate the type(s) of revenue source(s)",
  //   does_ur_biz_model_have_good_scalability: "Does the business model have a good scalability?",
  //   main_sector: "What is you main activity sector?",
  //   size_of_your_market: "What is the size of your market?",
  //   sam: "What is your SAM?",
  //   current_position: "What is your current position in your market segment?",
  //   expected_position_in_5_years: "What position can you reasonably expect to have in 5 years in your market segment?",
  //   does_entering_the_market_require_investments: "Does entering your market require significant material investments (Manufacturing resources, infrastructure, etc.) ?",
  //   the_amount_already_made_to_enter_the_market: "What is the amount of material investments already made to enter the market?",
  //   indicate_ur_authorities: "Indicate your regulation authority(ies)",
  //   have_you_obtained_auth: "Have you already obtained these authorizations?",
  //   refusal_rate: "What is the refusal rate of these authorizations?",
  //   how_long_will_it_take_to_obtain_them: "How long will it still take to obtain them ?",
  //   are_u_in_segment_where_network_effects_are_significant: "Are you in a market segment where network effects are significant?",
  //   how_intense_demand_for_feat: "How intense is the demand for special features in your market segment?",
  //   does_ur_offer_fit_into_an_existing_market: "Does your offer fit into an existing market or does it seek to create a new market?",

  //   your_competitors: "Your competitors",
  //   is_competitive_env_favorable: "Is the competitive environment favourable?",
  //   type_of_competitor: "Type of competitor",
  //   competitor_description: "In a short paragraph, describe your competitive advantage over this competitor",
  //   incubated_in_a_support_structure: "Are you (or have you been) incubated in a support structure (incubator, accelerator or innovation program)? ",
  //   list_incubators: "List incubators and accelerators (present and past)",
  //   structure_name: "Structure's name",
  //   structure: "Structure",
  //   current_presense_in_the_structure: "Current presence in the structure",
  //   date_of_entry_into_said_structure: "Date of entry into the said structure",
  //   structure_profile: "Structure profile",
  //   structure_website: "Structure website",
  //   what_exit_opportunities_are_you_considering: "What exit opportunities are you considering? ",
  //   expected_exit_time: "What is the expected exit time?",
  //   type_of_innovation: "What is the type of your innovation?",
  //   does_your_solution_use_existing_technologies: "Does your solution use existing technologies or is it the creation of a new technology?",
  //   how_many_tech_patents: "How many technological patents around your innovation have you registered to date?",
  //   describe_tech_patents: "Please describe the patents that have been filed",
  //   number_of_partnerships_signed_for_RandD: "Indicate the number of partnerships signed for R&D",
  //   have_an_award: "Do you have an award for your technological innovation?",
  //   specifiy_the_price: "Specify the prizes that you obtained:",
  //   how_many_shareholders: "How many shareholders does the company have? ",
  //   list_of_main_shareholders: "List of main shareholders and founders",
  //   name_of_shareholder: "Name of the shareholder",
  //   shareholder_profile: "Shareholder's profile",
  //   number_of_shares_head: "Number of shares held",
  //   describe_the_grants_recevied: "Describe the grants received",
  //   list_of_previous_fundraising: "List of previous fundraisings",
  //   have_leaders_already_raised_funds_in_prior_experiences: "Have the company's leaders already raised funds in prior experiences? ",
  //   amount_of_funds_raised: "Amount of funds raised",
  //   fundraising_date: "Fundraising date",
  //   enter_name_of_investores: "Enter the name of the investors and their profile",
  //   provide_details_of_the_founders_expertise: "Please provide details of the founders' expertise in corporate responsibility",
  //   details_of_action_taken: "Please provide details of the actions taken.",
  //   does_your_company_have_KPIs_oriented_towards_social_and_environmental_results: "Does your company have KPIs oriented towards social and environmental results?",
  //   upload_your_document: "Upload your document here",
};

export default Form;
