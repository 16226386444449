export const ENVIRONMENT = import.meta.env.MODE === "production" ? "production" : "development";
export const API_URL = import.meta.env.MODE === "production" ? "https://api.linkera.com" : "http://localhost:4000";
export const APP_URL = import.meta.env.MODE === "production" ? "https://app.linkera.com" : "http://localhost:3000";
export const PDF_URL = import.meta.env.MODE === "production" ? "https://pdf.linkera.com" : "http://localhost:3002";
export const SENTRY_DSN = "https://a465d7bd827c157b7d9888828f96e187@sentry.selego.co/101";
// Token expired in 12/02/2026
export const METABASE_TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyZXNvdXJjZSI6eyJkYXNoYm9hcmQiOjE4M30sInBhcmFtcyI6e30sImV4cCI6MTc3MDgyNzg1MywiaWF0IjoxNzA3NzU1ODU5fQ.FiFt3DdurXcZHJjPYv_-ldH4oz5iEuXMxInjF4e8AeE";

export const WILLIAM = "william@estimeo.com";
