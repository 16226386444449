import React from "react";

const EbitdaTable = ({ values }) => {
  const KEYS = ["ebitda", "taxes", "investments", "other_charges"];
  const KEYS_LABEL = {
    ebitda: "EBITDA",
    taxes: "Impôts",
    investments: "Investissements",
    other_charges: "Autres charges",
  };

  const years = Array.from(Array(11).keys()).map((i) => (values?.year_4 || 0) - 4 + i);

  return (
    <div className="w-full overflow-x-auto">
      <h3 className="font-semibold text-lg mb-2">EBE</h3>
      <table className="min-w-full bg-white border rounded-lg border-gray-300 table-fixed">
        <thead>
          <tr className="">
            <th colSpan="1" className="border text-gray-700 font-normal text-sm"></th>
            <th colSpan="4" className="border p-2  text-left text-gray-700 font-normal text-sm">
              {"Bilan passé"}
            </th>
            <th colSpan="7" className="border p-2 text-left text-gray-700 font-normal text-sm">
              {"Bilan prévisionnel"}
            </th>
          </tr>
          <tr className="text-sm">
            <th className="border p-2">{"Année"}</th>
            {years.map((year) => (
              <th key={year} className="border p-2">
                {year}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {KEYS.map((key) => (
            <tr key={key}>
              <td className="border p-2 text-xs w-56">{KEYS_LABEL[key]}</td>

              {years.map((year, index) => (
                <td key={year} className={`border text-xs px-2`}>
                  {key === "taxes" && values[`${key}_${index}`] < 0 ? 0 : values[`${key}_${index}`]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EbitdaTable;
