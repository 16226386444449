import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams, useNavigate } from "react-router-dom";

import api from "../../services/api";
import Loader from "../../components/Loader";

const Home = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState("infos");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await api.get(`/admin/${id}`);
        if (!res.ok) throw new Error(res.message);
        setUser(res.data);
      } catch (error) {
        toast.error("Une erreur est survenue");
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  const handleDelete = async () => {
    const confirm = window.confirm("Voulez-vous supprimer cet utilisateur ?");
    if (!confirm) return;
    try {
      const res = await api.delete(`/admin/${id}`);
      if (!res.ok) throw new Error(res.message);
      toast.success("Utilisateur supprimé");
      navigate("/user");
    } catch (error) {
      toast.error("Une erreur est survenue");
    }
  };

  if (loading) return <Loader />;

  return (
    <div className="p-8">
      <header className="w-full flex justify-between items-center">
        <h1 className="text-3xl font-semibold text-black mb-2">
          {user.first_name} {user.last_name}
        </h1>
        <div>
          <button type="button" className="red-button" onClick={handleDelete}>
            Delete
          </button>
        </div>
      </header>
      <div className="flex justify-between mb-4 mt-10">
        <div className="flex gap-5 mb-5">
          <button type="button" className={`rounded-lg px-4 py-2 ${view === "infos" ? "bg-gray-100" : "border border-secondary"}`} onClick={() => setView("infos")}>
            Informations
          </button>
          <button type="button" className={`rounded-lg px-4 py-2 ${view === "raw" ? "bg-gray-100" : "border border-secondary"}`} onClick={() => setView("raw")}>
            Données brutes
          </button>
        </div>
      </div>
      {view === "infos" ? (
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="text-primary text-sm font-medium">
              Prénom: <span className="text-sm text-black">{user.first_name}</span>
            </p>
            <p className="text-primary text-sm font-medium">
              Nom: <span className="text-sm text-black">{user.last_name}</span>
            </p>
            <p className="text-primary text-sm font-medium">
              E-mail: <span className="text-sm text-black">{user.email}</span>
            </p>
            <p className="text-primary text-sm font-medium">
              Numéro: <span className="text-sm text-black">{user.phone}</span>
            </p>
            <p className="text-primary text-sm font-medium">
              Créer le: <span className="text-sm text-black">{new Date(user.created_at).toLocaleDateString("fr-FR")}</span>
            </p>
            <p className="text-primary text-sm font-medium">
              Dernière connexion: <span className="text-sm text-black">{new Date(user.last_login_at).toLocaleDateString("fr-FR")}</span>
            </p>
          </div>
        </div>
      ) : (
        <div className="col-span-2 bg-gray-900 rounded-lg p-5 flex flex-col gap-2">
          <pre className="text-white text-sm">{JSON.stringify(user, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default Home;
