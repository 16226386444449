import React, { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";

import Modal from "../../../components/Modal";
import { dcf, ebitda, ebit, mbaAverage, cashFlowAndCAF, fiscale, simpleFiscale, productivity, netIncomeAverage } from "../../../valuations/sme";

export const DcfModal = ({ company, onSubmit, onClose }) => {
  const [values, setValues] = useState({ ...company });
  const [valuation, setValuation] = useState(dcf(company));
  const [showCompany, setShowCompany] = useState(true);
  const [showParams, setShowParams] = useState(true);
  const [showDetails, setShowDetails] = useState(true);

  useEffect(() => {
    setValues(company);
  }, [company]);

  useEffect(() => {
    setValuation(dcf(values));
  }, [values]);

  const STARTING_YEAR = values.year_4 || new Date().getFullYear();
  const HISTORIC_YEARS = Array.from(Array(4).keys());
  const PROJECTION_YEARS = Array.from(Array(9).keys());

  const updateAdjustments = (values) => {
    HISTORIC_YEARS.map((i) => {
      values[`adjustments_${i}`] =
        values[`company_rent_${i}`] + values[`leaders_salaries_${i}`] + values[`participation_${i}`] + values[`rents_leases_${i}`] + values[`stock_depreciations_${i}`];
    });
    return values;
  };

  const updateAddings = (values) => {
    HISTORIC_YEARS.map((i) => {
      values[`addings_${i}`] = values[`mean_rent_${i}`] + values[`mean_leader_salary_${i}`] + values[`rents_leases_DA_${i}`];
    });
    return values;
  };

  return (
    <Modal show={true} onClose={onClose} className="md:w-[80%] w-[90%] max-h-[95%] p-12">
      <h3 className="text-2xl text-center font-semibold mb-8">Méthode DCF</h3>
      <form
        className="w-full p-4"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(values);
          onClose();
        }}>
        <button type="button" onClick={() => setShowCompany(!showCompany)} className="w-full transparent-button flex justify-between">
          <h1 className="text-xl font-semibold">Informations financières</h1>

          <span>{showCompany ? "▲" : "▼"}</span>
        </button>

        {showCompany && (
          <table className="w-full table-fixed">
            <thead>
              <tr>
                <th />
                {HISTORIC_YEARS.map((year) => (
                  <th key={year} className="text-right">
                    {STARTING_YEAR - 4 + year}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Chiffre d'affaires</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`turnover_${i}`]}
                      onValueChange={(_, name, value) => setValues({ ...values, [`turnover_${i}`]: value.float })}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Excédent Brut d'Exploitation</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`ebitda_${i}`]}
                      onValueChange={(_, name, value) => setValues({ ...values, [`ebitda_${i}`]: value.float })}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Retraitements</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      disabled
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`adjustments_${i}`]}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold"> - Loyer de l'entreprise</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`company_rent_${i}`]}
                      onValueChange={(_, name, value) => {
                        const newValues = updateAdjustments({ ...values, [`company_rent_${i}`]: value.float });
                        setValues(newValues);
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold"> - Salaire des dirigeants</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`leaders_salaries_${i}`]}
                      onValueChange={(_, name, value) => {
                        const newValues = updateAdjustments({ ...values, [`leaders_salaries_${i}`]: value.float });
                        setValues(newValues);
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold"> - Participation</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`participation_${i}`]}
                      onValueChange={(_, name, value) => {
                        const newValues = updateAdjustments({ ...values, [`participation_${i}`]: value.float });
                        setValues(newValues);
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold"> - Crédits-bails</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`rents_leases_${i}`]}
                      onValueChange={(_, name, value) => {
                        const newValues = updateAdjustments({ ...values, [`rents_leases_${i}`]: value.float });
                        setValues(newValues);
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold"> - Dépréciations sur les stocks</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`stock_depreciations_${i}`]}
                      onValueChange={(_, name, value) => {
                        const newValues = updateAdjustments({ ...values, [`stock_depreciations_${i}`]: value.float });
                        setValues(newValues);
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary bg-gray-50">
                <td className="text-xs font-semibold">Ajouts</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      disabled
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`addings_${i}`]}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold"> - Loyer Moyen</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`mean_rent_${i}`]}
                      onValueChange={(_, name, value) => {
                        const newValues = updateAddings({ ...values, [`mean_rent_${i}`]: value.float });
                        setValues(newValues);
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold"> - Salaire moyen dirigeant</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`mean_leader_salary_${i}`]}
                      onValueChange={(_, name, value) => {
                        const newValues = updateAddings({ ...values, [`mean_leader_salary_${i}`]: value.float });
                        setValues(newValues);
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold"> - D&A des crédits-bails</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`rents_leases_DA_${i}`]}
                      onValueChange={(_, name, value) => {
                        const newValues = updateAddings({ ...values, [`rents_leases_DA_${i}`]: value.float });
                        setValues(newValues);
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Dette</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`debt_${i}`]}
                      onValueChange={(_, name, value) => {
                        setValues({ ...values, [`debt_${i}`]: value.float });
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Trésorerie</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`treasury_${i}`]}
                      onValueChange={(_, name, value) => {
                        setValues({ ...values, [`treasury_${i}`]: value.float });
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary  bg-gray-50">
                <td className="text-xs font-semibold">Excédent Brut d'Exploitation retraité</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i} className="text-right text-base">
                    <CurrencyInput
                      disabled
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={valuation.details.adujestedEbitdaProjections[i]}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Dotations aux amortissements</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`dna_${i}`]}
                      onValueChange={(_, name, value) => setValues({ ...values, [`dna_${i}`]: value.float })}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary bg-gray-50">
                <td className="text-xs font-semibold">Marge (%) D&A</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i} className="text-right">
                    {(values[`dna_${i}`] / values[`turnover_${i}`] || 1).toLocaleString("fr-FR", { style: "percent", minimumFractionDigits: 2 })}
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Marge (%) D&A</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`operating_income_${i}`]}
                      onValueChange={(_, name, value) => setValues({ ...values, [`operating_income_${i}`]: value.float })}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Résultat net</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`net_income_${i}`]}
                      onValueChange={(_, name, value) => setValues({ ...values, [`net_income_${i}`]: value.float })}
                    />
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        )}

        <button type="button" onClick={() => setShowParams(!showParams)} className="w-full transparent-button flex justify-between">
          <h1 className="text-xl font-semibold">Paramètres</h1>

          <span>{showParams ? "▲" : "▼"}</span>
        </button>
        {showParams && (
          <table className="w-1/2 table-fixed">
            <tbody>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Taux de croissance moyen de l'industrie (/1)</td>

                <td className="text-right">
                  <input
                    type="number"
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.dcf_industry_growth_rate}
                    onChange={(e) => setValues({ ...values, dcf_industry_growth_rate: Number(e.target.value) })}
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Taux de croissance à l'infini (/1)</td>

                <td className="text-right">
                  <input
                    type="number"
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.dcf_infinite_growth_rate}
                    onChange={(e) => setValues({ ...values, dcf_infinite_growth_rate: Number(e.target.value) })}
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Facteur d'évolution</td>

                <td className="text-right">
                  <input
                    type="number"
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.dcf_evolution_factor}
                    onChange={(e) => setValues({ ...values, dcf_evolution_factor: Number(e.target.value) })}
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Poids arithmétique</td>

                <td className="text-right">
                  <input
                    type="number"
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.dcf_arithmetic_weight}
                    onChange={(e) => setValues({ ...values, dcf_arithmetic_weight: Number(e.target.value) })}
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Taux de l'IS</td>

                <td className="text-right">
                  <input
                    type="number"
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.taxes_rate}
                    onChange={(e) => setValues({ ...values, taxes_rate: Number(e.target.value) })}
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">WACC (/1)</td>

                <td className="flex items-center justify-end">
                  <input
                    type="number"
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.dcf_wacc}
                    onChange={(e) => setValues({ ...values, dcf_wacc: Number(e.target.value) })}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        )}

        <button type="button" onClick={() => setShowDetails(!showDetails)} className="w-full transparent-button flex justify-between">
          <h1 className="text-xl font-semibold">Détails de la valorisation</h1>

          <span>{showDetails ? "▲" : "▼"}</span>
        </button>
        {showDetails && (
          <table className="block w-full table-fixed text-sm overflow-auto [border-spacing:1rem]">
            <thead>
              <tr>
                <th colSpan={2} />
                {PROJECTION_YEARS.map((year) => (
                  <th key={year} className="text-right">
                    {STARTING_YEAR + year}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr className="bg-secondary">
                <td colSpan={PROJECTION_YEARS.length + 2}>
                  <h3 className="font-semibold text-xs text-center">Projection</h3>
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td colSpan={2} className="text-xs font-semibold">
                  Chiffre d'affaires
                </td>
                {PROJECTION_YEARS.map((year) => (
                  <td key={year} className="text-right px-2">
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={valuation.details.turnoversProjections[year + 4]}
                      onValueChange={(_, name, value) => {
                        setValues({ ...values, [`turnover_${year + 4}`]: value.float });
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary bg-gray-50">
                <td colSpan={2} className="text-xs font-semibold">
                  Croissance du chiffre d'affaires
                </td>
                {PROJECTION_YEARS.map((year) => (
                  <td key={year} className="text-right px-2">
                    {valuation.details.turnoversGrowth[year + 4].toLocaleString("fr-FR", { style: "percent", minimumFractionDigits: 2 })}
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td colSpan={2} className="text-xs font-semibold">
                  Excédent Brut d'Exploitation Retraité
                </td>
                {PROJECTION_YEARS.map((year) => (
                  <td key={year} className="text-right px-2">
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={valuation.details.adujestedEbitdaProjections[year + 4]}
                      onValueChange={(_, name, value) => {
                        setValues({ ...values, [`ebitda_${year + 4}`]: value.float });
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary bg-gray-50">
                <td colSpan={2} className="text-xs font-semibold">
                  Marge EBE
                </td>
                {PROJECTION_YEARS.map((year) => (
                  <td key={year} className="text-right px-2">
                    {valuation.details.ebitdaMarginProjections[year + 4].toLocaleString("fr-FR", { style: "percent", minimumFractionDigits: 2 })}
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary bg-gray-50">
                <td colSpan={2} className="text-xs font-semibold">
                  Dotations au amortissements
                </td>
                {PROJECTION_YEARS.map((year) => (
                  <td key={year} className="text-right px-2">
                    {valuation.details.dnaProjections[year + 4].toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary bg-gray-50">
                <td colSpan={2} className="text-xs font-semibold">
                  Marge (%) D&A
                </td>
                {PROJECTION_YEARS.map((year) => (
                  <td key={year} className="text-right px-2">
                    {valuation.details.dnaMarginProjections[year + 4].toLocaleString("fr-FR", { style: "percent", minimumFractionDigits: 2 })}
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary ">
                <td colSpan={2} className="text-xs font-semibold">
                  Autres produits, charges et reprises
                </td>
                {PROJECTION_YEARS.map((year) => (
                  <td key={year} className="text-right px-2">
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`other_charge_${year + 4}`] || valuation.details.otherChargeProjections[year + 4]}
                      onValueChange={(_, name, value) => setValues({ ...values, [`other_charge_${year + 4}`]: value.float })}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td colSpan={2} className="text-xs font-semibold">
                  Résultat d'exploitation
                </td>
                {PROJECTION_YEARS.map((year) => (
                  <td key={year} className="text-right px-2">
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={valuation.details.operatingIncomeProjections[year + 4]}
                      onValueChange={(_, name, value) => {
                        setValues({ ...values, [`operating_income_${year + 4}`]: value.float });
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary bg-gray-50">
                <td colSpan={2} className="text-xs font-semibold">
                  Marge REx
                </td>
                {PROJECTION_YEARS.map((year) => (
                  <td key={year} className="text-right px-2">
                    -
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary bg-gray-50">
                <td colSpan={2} className="text-xs font-semibold">
                  Resultat financier
                </td>
                {PROJECTION_YEARS.map((year) => (
                  <td key={year} className="text-right px-2">
                    -
                  </td>
                ))}
              </tr>
              <tr className="bg-secondary">
                <td colSpan={PROJECTION_YEARS.length + 2}>
                  <h3 className="font-semibold text-xs text-center">Calcul des FTDs</h3>
                </td>
              </tr>
              <tr className="border-y border-secondary bg-gray-50">
                <td colSpan={2} className="text-xs font-semibold">
                  Impôt sur les sociétés
                </td>
                {PROJECTION_YEARS.map((year) => (
                  <td key={year} className="text-right px-2">
                    {(valuation.details.societyTaxes[year] || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary bg-gray-50">
                <td colSpan={2} className="text-xs font-semibold">
                  Investissements
                </td>
                {PROJECTION_YEARS.map((year) => (
                  <td key={year} className="text-right px-2">
                    -
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td colSpan={2} className="text-xs font-semibold">
                  BFR
                </td>
                {PROJECTION_YEARS.map((year) => (
                  <td key={year} className="text-right px-2">
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={valuation.details.workingCapitalProjections[year + 4]}
                      onValueChange={(_, name, value) => {
                        setValues({ ...values, [`working_capital_${year + 4}`]: value.float });
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary bg-gray-50">
                <td colSpan={2} className="text-xs font-semibold">
                  Variation du BFR
                </td>
                {PROJECTION_YEARS.map((year) => (
                  <td key={year} className="text-right px-2">
                    {(valuation.details.netWorkingCapital[year + 4] || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary bg-gray-50">
                <td colSpan={2} className="text-xs font-semibold">
                  FTD
                </td>
                {PROJECTION_YEARS.map((year) => (
                  <td key={year} className="text-right px-2">
                    {(valuation.details.freeCashFlows[year] || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary bg-gray-50">
                <td colSpan={2} className="text-xs font-semibold">
                  Valeur terminale en fonction des années
                </td>
                {PROJECTION_YEARS.map((year) => (
                  <td key={year} className="text-right px-2">
                    {(valuation.details.terminalValues[year] || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
                  </td>
                ))}
              </tr>
              <tr className="bg-secondary">
                <td colSpan={PROJECTION_YEARS.length + 2}>
                  <h3 className="font-semibold text-xs text-center">Calcul des FTDS actualisés</h3>
                </td>
              </tr>

              <tr className="border-y border-secondary bg-gray-50">
                <td colSpan={2} className="text-xs font-semibold">
                  FTDs actualisés
                </td>
                {PROJECTION_YEARS.map((year) => (
                  <td key={year} className="text-right px-2">
                    {(valuation.details.actualizedFreeCashFlows[year] || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary bg-gray-50">
                <td colSpan={2} className="text-xs font-semibold">
                  Valeurs terminales actualisées
                </td>
                {PROJECTION_YEARS.map((year) => (
                  <td key={year} className="text-right px-2">
                    {(valuation.details.actualizedTerminalValue[year] || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
                  </td>
                ))}
              </tr>
              <tr className="bg-secondary">
                <td colSpan={PROJECTION_YEARS.length + 2}>
                  <h3 className="font-semibold text-xs text-center">Valorisation</h3>
                </td>
              </tr>
              <tr className="border-y border-secondary bg-gray-50">
                <td colSpan={2} className="text-xs font-semibold">
                  Valeurs des capitaux propres
                </td>
                {PROJECTION_YEARS.map((year) => (
                  <td key={year} className="text-right px-2">
                    {(valuation.result[year] || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        )}

        <div className="w-full flex justify-center mt-4">
          <div className="w-1/2 p-4 border border-secondary rounded-lg flex flex-col items-center gap-4">
            <h1 className="text-xl text-center font-semibold mb-4">Résultat</h1>
            <div className="w-full flex justify-between items-center px-10">
              <div className="flex items-center ">
                <label className="font-medium text-gray-700 pr-4">Année</label>
                <select
                  value={values.dcf_year}
                  onChange={(e) => setValues({ ...values, dcf_year: e.target.value })}
                  className="bg-transparent"
                  onClick={(e) => e.stopPropagation()}>
                  <option value={0}>1</option>
                  <option value={1}>2</option>
                  <option value={2}>3</option>
                  <option value={3}>4</option>
                  <option value={4}>5</option>
                  <option value={5}>6</option>
                  <option value={6}>7</option>
                  <option value={7}>8</option>
                  <option value={8}>9</option>
                </select>
              </div>
              <span className="text-lg font-semibold">
                {valuation.result[values.dcf_year < valuation.result.length ? values.dcf_year : 4].toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
              </span>
            </div>
            <button className="primary-button" type="submit">
              Valider
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export const EbitdaModal = ({ company, onSubmit, onClose }) => {
  const [values, setValues] = useState({ ...company });
  const [valuation, setValuation] = useState(ebitda(company));
  const [showCompany, setShowCompany] = useState(true);
  const [showParams, setShowParams] = useState(true);
  const [showDetails, setShowDetails] = useState(true);

  useEffect(() => {
    setValues(company);
  }, [company]);

  useEffect(() => {
    setValuation(ebitda(values));
  }, [values]);

  const STARTING_YEAR = values.year_4 || new Date().getFullYear();
  const HISTORIC_YEARS = Array.from(Array(3).keys()).map((i) => i + 1);

  const SECTORS = [
    "Biens de consommation",
    "Biens d’équipement",
    "Distribution",
    "Energie / Service publics",
    "Immobilier",
    "Industrie automobile",
    "Matériaux",
    "Média / Communication",
    "Santé / médical",
    "Services aux consommateurs",
    "Services aux entreprises",
    "Technologies de l’information",
    "Télécommunication",
    "Transport & logistique",
    "Services financiers",
  ];

  const updateAdjustments = (values) => {
    HISTORIC_YEARS.map((i) => {
      values[`adjustments_${i}`] =
        values[`company_rent_${i}`] + values[`leaders_salaries_${i}`] + values[`participation_${i}`] + values[`rents_leases_${i}`] + values[`stock_depreciations_${i}`];
    });
    return values;
  };

  const updateAddings = (values) => {
    HISTORIC_YEARS.map((i) => {
      values[`addings_${i}`] = values[`mean_rent_${i}`] + values[`mean_leader_salary_${i}`] + values[`rents_leases_DA_${i}`];
    });
    return values;
  };

  return (
    <Modal show={true} onClose={onClose} className="md:w-[80%] w-[90%] max-h-[95%] p-12">
      <h3 className="text-2xl text-center font-semibold mb-8">Méthode EBE</h3>

      <form
        className="w-full p-4"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(values);
          onClose();
        }}>
        <button onClick={() => setShowCompany(!showCompany)} className="w-full transparent-button flex justify-between">
          <h1 className="text-xl font-semibold">Informations financières</h1>

          <span>{showCompany ? "▲" : "▼"}</span>
        </button>

        {showCompany && (
          <table className="w-full table-fixed">
            <thead>
              <tr>
                <th />
                {HISTORIC_YEARS.map((year) => (
                  <th key={year} className="text-right">
                    {STARTING_YEAR - 4 + year}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Excédent Brut d'Exploitation</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`ebitda_${i}`]}
                      onValueChange={(_, name, value) => setValues({ ...values, [`ebitda_${i}`]: value.float })}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Retraitements</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      disabled
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`adjustments_${i}`]}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold"> - Loyer de l'entreprise</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`company_rent_${i}`]}
                      onValueChange={(_, name, value) => {
                        const newValues = updateAdjustments({ ...values, [`company_rent_${i}`]: value.float });
                        setValues(newValues);
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold"> - Salaire des dirigeants</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`leaders_salaries_${i}`]}
                      onValueChange={(_, name, value) => {
                        const newValues = updateAdjustments({ ...values, [`leaders_salaries_${i}`]: value.float });
                        setValues(newValues);
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold"> - Participation</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`participation_${i}`]}
                      onValueChange={(_, name, value) => {
                        const newValues = updateAdjustments({ ...values, [`participation_${i}`]: value.float });
                        setValues(newValues);
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold"> - Crédits-bails</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`rents_leases_${i}`]}
                      onValueChange={(_, name, value) => {
                        const newValues = updateAdjustments({ ...values, [`rents_leases_${i}`]: value.float });
                        setValues(newValues);
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold"> - Dépréciations sur les stocks</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`stock_depreciations_${i}`]}
                      onValueChange={(_, name, value) => {
                        const newValues = updateAdjustments({ ...values, [`stock_depreciations_${i}`]: value.float });
                        setValues(newValues);
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Ajouts</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      disabled
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`addings_${i}`]}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold"> - Loyer Moyen</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`mean_rent_${i}`]}
                      onValueChange={(_, name, value) => {
                        const newValues = updateAddings({ ...values, [`mean_rent_${i}`]: value.float });
                        setValues(newValues);
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold"> - Salaire moyen dirigeant</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`mean_leader_salary_${i}`]}
                      onValueChange={(_, name, value) => {
                        const newValues = updateAddings({ ...values, [`mean_leader_salary_${i}`]: value.float });
                        setValues(newValues);
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold"> - D&A des crédits-bails</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`rents_leases_DA_${i}`]}
                      onValueChange={(_, name, value) => {
                        const newValues = updateAddings({ ...values, [`rents_leases_DA_${i}`]: value.float });
                        setValues(newValues);
                      }}
                    />
                  </td>
                ))}
              </tr>

              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Excédent Brut d'Exploitation retraité</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i} className="text-right">
                    {valuation.details.adjustedEbitda[i].toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Pondération des années</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <input
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      type="number"
                      value={values[`ebitda_weighting_${i}`]}
                      onChange={(e) => setValues({ ...values, [`ebitda_weighting_${i}`]: Number(e.target.value) })}
                    />
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        )}

        <button onClick={() => setShowParams(!showParams)} className="w-full transparent-button flex justify-between">
          <h1 className="text-xl font-semibold">Paramètres</h1>

          <span>{showParams ? "▲" : "▼"}</span>
        </button>
        {showParams && (
          <table className="w-1/2 table-fixed">
            <tbody>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Secteur</td>
                <td className="text-right">
                  <select
                    className="w-full h-full bg-transparent border-none text-right focus:outline-none"
                    value={values.company_sector}
                    onChange={(e) => setValues({ ...values, company_sector: e.target.value, ebitda_factor: null })}>
                    {SECTORS.map((sector) => (
                      <option key={sector} value={sector}>
                        {sector}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Multiple EBE</td>
                <td className="text-right">
                  <input
                    type="number"
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={valuation.details.ebitda_factor}
                    onChange={(e) => setValues({ ...values, ebitda_factor: Number(e.target.value) })}
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Facteur de risque</td>

                <td className="text-right">
                  <input
                    type="number"
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.ebitda_risk_factor}
                    onChange={(e) => setValues({ ...values, ebitda_risk_factor: Number(e.target.value) })}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        )}

        <button onClick={() => setShowDetails(!showDetails)} className="w-full transparent-button flex justify-between">
          <h1 className="text-xl font-semibold">Détails de la valorisation</h1>

          <span>{showDetails ? "▲" : "▼"}</span>
        </button>
        {showDetails && (
          <table className="w-full table-fixed">
            <tbody>
              <tr className="border-y border-secondary">
                <td colSpan={2}>
                  <h3 className="font-semibold text-center">Calcul de l'EBE retraité pondéré</h3>
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td colSpan={2} className="text-xs font-semibold">
                  EBE moyen pondéré
                </td>
                <td className="text-right">{valuation.details.ebitdaAverage.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
              </tr>
            </tbody>
          </table>
        )}

        <div className="w-full mt-4 flex justify-center">
          <div className="w-1/2 p-4 border border-secondary rounded-lg flex flex-col items-center gap-4">
            <h1 className="text-xl text-center font-semibold mb-4">Résultat</h1>
            <p className="text-lg text-center font-semibold">{valuation.result.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</p>
            <button className="primary-button" type="submit">
              Valider
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export const EbitModal = ({ company, onSubmit, onClose }) => {
  const [values, setValues] = useState({ ...company });
  const [valuation, setValuation] = useState(ebit(company));
  const [showCompany, setShowCompany] = useState(true);
  const [showParams, setShowParams] = useState(true);
  const [showDetails, setShowDetails] = useState(true);

  useEffect(() => {
    setValues(company);
  }, [company]);

  useEffect(() => {
    setValuation(ebit(values));
  }, [values]);

  const STARTING_YEAR = values.year_4 || new Date().getFullYear();
  const HISTORIC_YEARS = Array.from(Array(3).keys()).map((i) => i + 1);

  const SECTORS = [
    "Biens de consommation",
    "Biens d’équipement",
    "Distribution",
    "Energie / Service publics",
    "Immobilier",
    "Industrie automobile",
    "Matériaux",
    "Média / Communication",
    "Santé / médical",
    "Services aux consommateurs",
    "Services aux entreprises",
    "Technologies de l’information",
    "Télécommunication",
    "Transport & logistique",
    "Services financiers",
  ];

  const updateAdjustments = (values) => {
    HISTORIC_YEARS.map((i) => {
      values[`adjustments_${i}`] =
        values[`company_rent_${i}`] + values[`leaders_salaries_${i}`] + values[`participation_${i}`] + values[`rents_leases_${i}`] + values[`stock_depreciations_${i}`];
    });
    return values;
  };

  const updateAddings = (values) => {
    HISTORIC_YEARS.map((i) => {
      values[`addings_${i}`] = values[`mean_rent_${i}`] + values[`mean_leader_salary_${i}`] + values[`rents_leases_DA_${i}`];
    });
    return values;
  };

  return (
    <Modal show={true} onClose={onClose} className="md:w-[80%] w-[90%] max-h-[95%] p-12">
      <h3 className="text-2xl text-center font-semibold mb-8">Méthode EBIT</h3>
      <form
        className="w-full p-4"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(values);
          onClose();
        }}>
        <button onClick={() => setShowCompany(!showCompany)} className="w-full transparent-button flex justify-between">
          <h1 className="text-xl font-semibold">Informations financières</h1>

          <span>{showCompany ? "▲" : "▼"}</span>
        </button>

        {showCompany && (
          <table className="w-full table-fixed">
            <thead>
              <tr>
                <th />
                {HISTORIC_YEARS.map((year) => (
                  <th key={year} className="text-right">
                    {STARTING_YEAR - 4 + year}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Excédent Brut d'Exploitation</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`ebitda_${i}`]}
                      onValueChange={(_, name, value) => setValues({ ...values, [`ebitda_${i}`]: value.float })}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Retraitements</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      disabled
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`adjustments_${i}`]}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold"> - Loyer de l'entreprise</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`company_rent_${i}`]}
                      onValueChange={(_, name, value) => {
                        const newValues = updateAdjustments({ ...values, [`company_rent_${i}`]: value.float });
                        setValues(newValues);
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold"> - Salaire des dirigeants</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`leaders_salaries_${i}`]}
                      onValueChange={(_, name, value) => {
                        const newValues = updateAdjustments({ ...values, [`leaders_salaries_${i}`]: value.float });
                        setValues(newValues);
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold"> - Participation</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`participation_${i}`]}
                      onValueChange={(_, name, value) => {
                        const newValues = updateAdjustments({ ...values, [`participation_${i}`]: value.float });
                        setValues(newValues);
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold"> - Crédits-bails</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`rents_leases_${i}`]}
                      onValueChange={(_, name, value) => {
                        const newValues = updateAdjustments({ ...values, [`rents_leases_${i}`]: value.float });
                        setValues(newValues);
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold"> - Dépréciations sur les stocks</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`stock_depreciations_${i}`]}
                      onValueChange={(_, name, value) => {
                        const newValues = updateAdjustments({ ...values, [`stock_depreciations_${i}`]: value.float });
                        setValues(newValues);
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Ajouts</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      disabled
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`addings_${i}`]}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold"> - Loyer Moyen</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`mean_rent_${i}`]}
                      onValueChange={(_, name, value) => {
                        const newValues = updateAddings({ ...values, [`mean_rent_${i}`]: value.float });
                        setValues(newValues);
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold"> - Salaire moyen dirigeant</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`mean_leader_salary_${i}`]}
                      onValueChange={(_, name, value) => {
                        const newValues = updateAddings({ ...values, [`mean_leader_salary_${i}`]: value.float });
                        setValues(newValues);
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold"> - D&A des crédits-bails</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`rents_leases_DA_${i}`]}
                      onValueChange={(_, name, value) => {
                        const newValues = updateAddings({ ...values, [`rents_leases_DA_${i}`]: value.float });
                        setValues(newValues);
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Excédent Brut d'Exploitation retraité</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i} className="text-right">
                    {valuation.details.adjustedEbitda[i].toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Dotations aux amortissements</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i} className="text-right">
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={valuation.details.dna[i]}
                      onValueChange={(_, name, value) => setValues({ ...values, [`dna_${i}`]: value.float })}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Autres produits, charges et reprises</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i} className="text-right">
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={valuation.details.otherCharges[i]}
                      onValueChange={(_, name, value) => setValues({ ...values, [`other_charges_${i}`]: value.float })}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Résultat d'exploitation retraité</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i} className="text-right">
                    {valuation.details.ebit[i].toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
                  </td>
                ))}
              </tr>

              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Pondération des années</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <input
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      type="number"
                      value={values[`ebit_weighting_${i}`]}
                      onChange={(e) => setValues({ ...values, [`ebit_weighting_${i}`]: Number(e.target.value) })}
                    />
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        )}

        <button onClick={() => setShowParams(!showParams)} className="w-full transparent-button flex justify-between">
          <h1 className="text-xl font-semibold">Paramètres</h1>

          <span>{showParams ? "▲" : "▼"}</span>
        </button>
        {showParams && (
          <table className="w-1/2 table-fixed">
            <tbody>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Secteur</td>
                <td className="text-right">
                  <select
                    className="w-full h-full bg-transparent border-none text-right focus:outline-none"
                    value={values.company_sector}
                    onChange={(e) => setValues({ ...values, company_sector: e.target.value, ebit_factor: null })}>
                    {SECTORS.map((sector) => (
                      <option key={sector} value={sector}>
                        {sector}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Multiple EBE</td>
                <td className="text-right">
                  <input
                    type="number"
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={valuation.details.ebit_factor}
                    onChange={(e) => setValues({ ...values, ebit_factor: Number(e.target.value) })}
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Facteur de risque</td>

                <td className="text-right">
                  <input
                    type="number"
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.ebit_risk_factor}
                    onChange={(e) => setValues({ ...values, ebit_risk_factor: Number(e.target.value) })}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        )}

        <button onClick={() => setShowDetails(!showDetails)} className="w-full transparent-button flex justify-between">
          <h1 className="text-xl font-semibold">Détails de la valorisation</h1>

          <span>{showDetails ? "▲" : "▼"}</span>
        </button>
        {showDetails && (
          <table className="w-full table-fixed">
            <tbody>
              <tr className="border-y border-secondary">
                <td colSpan={2}>
                  <h3 className="font-semibold text-center">Calcul de l'EBE retraité pondéré</h3>
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-sm font-semibold">EBE moyen pondéré</td>
                <td className="text-right">{valuation.details.ebitAverage.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
              </tr>
            </tbody>
          </table>
        )}

        <div className="w-full mt-4 flex justify-center">
          <div className="w-1/2 p-4 border border-secondary rounded-lg flex flex-col items-center gap-4">
            <h1 className="text-xl text-center font-semibold mb-4">Résultat</h1>
            <p className="text-lg text-center font-semibold">{valuation.result.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</p>
            <button className="primary-button" type="submit">
              Valider
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

// UNUSED - UNTESTED
export const FiscaleModal = ({ company, onSubmit, onClose }) => {
  const [values, setValues] = useState({ ...company });
  const [valuation, setValuation] = useState(fiscale(company));

  useEffect(() => {
    setValues(company);
  }, [company]);

  useEffect(() => {
    setValuation(fiscale(values));
  }, [values]);

  const calculatedEbitdaFactor = values.fiscale_industry_theoretical_factor * values.fiscale_profitability_theorical_factor * values.fiscale_asset_theorical_factor;
  const ebitdaFactor = values.fiscale_factor_pertinent ? calculatedEbitdaFactor : values.fiscale_given_ebitda_factor;

  return (
    <Modal show={true} onClose={onClose} className="md:w-[80%] w-[90%] max-h-[95%] p-12">
      <h3 className="text-2xl text-center font-semibold mb-8">Méthode fiscale</h3>

      <form
        className="flex flex-wrap -mx-2 mt-4"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(values);
          onClose();
        }}>
        <div className="mb-4 w-full px-2 flex gap-5 items-center">
          <label className=" text-sm font-medium text-gray-700" htmlFor="isFactorPertinent">
            Is Factor Pertinent
          </label>
          <input
            type="checkbox"
            id="isFactorPertinent"
            className="checkbox"
            checked={values.fiscale_factor_pertinent}
            onChange={(e) => setValues({ ...values, fiscale_factor_pertinent: e.target.checked })}
          />
        </div>
        <div className="w-full sm:w-1/2 mb-4 px-2">
          <label className="block text-sm font-medium text-gray-700 mb-2">Industry Theoretical Factor</label>
          <input
            type="number"
            step="any"
            className="input"
            value={values.fiscale_industry_theoretical_factor}
            onChange={(e) => setValues({ ...values, fiscale_industry_theoretical_factor: Number(e.target.value) })}
          />
        </div>
        <div className="w-full sm:w-1/2 mb-4 px-2">
          <label className="block text-sm font-medium text-gray-700 mb-2">Profitability Theoretical Factor</label>
          <input
            type="number"
            step="any"
            className="input"
            value={values.fiscale_profitability_theorical_factor}
            onChange={(e) => setValues({ ...values, fiscale_profitability_theorical_factor: Number(e.target.value) })}
          />
        </div>
        <div className="w-full sm:w-1/2 mb-4 px-2">
          <label className="block text-sm font-medium text-gray-700 mb-2">Asset Theoretical Factor</label>
          <input
            type="number"
            step="any"
            className="input"
            value={values.fiscale_asset_theorical_factor}
            onChange={(e) => setValues({ ...values, fiscale_asset_theorical_factor: Number(e.target.value) })}
          />
        </div>
        <div className="w-full sm:w-1/2 mb-4 px-2">
          <label className="block text-sm font-medium text-gray-700 mb-2">Given EBITDA Factor</label>
          <input
            type="number"
            step="any"
            className="input"
            value={values.fiscale_given_ebitda_factor}
            onChange={(e) => setValues({ ...values, fiscale_given_ebitda_factor: Number(e.target.value) })}
          />
        </div>
        <p className="w-full mb-4 px-2  text-sm font-semibold mt-3">
          Calculated Ebitda Factor : <span className="px-3 py-2">{calculatedEbitdaFactor}</span>
        </p>

        <p className="w-full px-2 text-sm font-semibold mb-7">
          Ebitda Factor : <span className="px-3 py-2 mt-2">{ebitdaFactor}</span>
        </p>

        <label className="block font-medium text-gray-700 mb-2">Pondération</label>
        <input
          type="number"
          step="0.01"
          min="0"
          max="1"
          className="input"
          value={values.fiscale_coefficient}
          onChange={(e) => setValues({ ...values, fiscale_coefficient: Number(e.target.value) })}
        />

        <div className="w-full mt-4 flex justify-center">
          <div className="w-1/2 p-4 border border-secondary rounded-lg flex flex-col items-center gap-4">
            <h2 className="text-xl text-center font-semibold">Résultat</h2>
            <p className="text-lg text-center font-semibold">{valuation.result.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</p>
            <button className="primary-button" type="submit">
              Valider
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export const SimpleFiscaleModal = ({ company, onSubmit, onClose }) => {
  const [values, setValues] = useState({ ...company });
  const [valuation, setValuation] = useState(simpleFiscale(company));

  useEffect(() => {
    setValues(company);
  }, [company]);

  useEffect(() => {
    setValuation(simpleFiscale(values));
  }, [values]);

  return (
    <Modal show={true} onClose={onClose} className="md:w-[80%] w-[90%] max-h-[95%] p-12">
      <h3 className="text-2xl text-center font-semibold mb-8">Méthode fiscale</h3>
      <form
        className="flex flex-wrap -mx-2 mt-4"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(values);
          onClose();
        }}>
        <div className="flex justify-between items-center w-full">
          <div className="flex flex-col mb-4 w-full px-2 gap-5 items-center">
            <label className=" text-sm font-medium text-gray-700" htmlFor="isFactorPertinent">
              Valorisation patrimoniale
            </label>
            <CurrencyInput
              className="w-fit h-full appearance-none bg-transparent border-none text-right focus:outline-none"
              intlConfig={{ locale: "fr-FR", currency: "EUR" }}
              decimalsLimit={2}
              value={valuation.details.patrimoniale}
              onValueChange={(_, name, value) => setValues({ ...values, valuation_patrimonial: value.float })}
            />
          </div>
          <div className="flex flex-col mb-4 w-full px-2 gap-5 items-center">
            <label className=" text-sm font-medium text-gray-700" htmlFor="isFactorPertinent">
              Pondération patrimoniale
            </label>
            <input
              type="number"
              step="any"
              className="input w-fit"
              value={values.fiscale_patrimonial_factor}
              onChange={(e) => setValues({ ...values, fiscale_patrimonial_factor: Number(e.target.value) })}
            />
          </div>
        </div>
        <div className="flex justify-between items-center w-full">
          <div className="flex flex-col mb-4 w-full px-2 gap-5 items-center">
            <label className=" text-sm font-medium text-gray-700" htmlFor="isFactorPertinent">
              Valorisation EBE
            </label>
            <CurrencyInput
              className="w-fit h-full appearance-none bg-transparent border-none text-right focus:outline-none"
              intlConfig={{ locale: "fr-FR", currency: "EUR" }}
              decimalsLimit={2}
              value={valuation.details.ebe}
              onValueChange={(_, name, value) => setValues({ ...values, valuation_ebitda: value.float })}
              disabled
            />
          </div>
          <div className="flex flex-col mb-4 w-full px-2 gap-5 items-center">
            <label className=" text-sm font-medium text-gray-700" htmlFor="isFactorPertinent">
              Pondération EBE
            </label>
            <input
              type="number"
              step="any"
              className="input w-fit"
              value={values.fiscale_ebe_factor}
              onChange={(e) => setValues({ ...values, fiscale_ebe_factor: Number(e.target.value) })}
            />
          </div>
        </div>
        <div className="w-full mt-4 flex justify-center">
          <div className="w-1/2 p-4 border border-secondary rounded-lg flex flex-col items-center gap-4">
            <h2 className="text-xl text-center font-semibold">Résultat</h2>
            <p className="text-lg text-center font-semibold">{valuation.result.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</p>
            <button className="primary-button" type="submit">
              Valider
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export const ProductivityModal = ({ company, onSubmit, onClose }) => {
  const [values, setValues] = useState({ ...company });
  const [valuation, setValuation] = useState(productivity(company));
  const [showCompany, setShowCompany] = useState(true);
  const [showParams, setShowParams] = useState(true);
  const [showDetails, setShowDetails] = useState(true);

  useEffect(() => {
    setValues(company);
  }, [company]);

  useEffect(() => {
    setValuation(productivity(values));
  }, [values]);

  const STARTING_YEAR = values.year_4 || new Date().getFullYear();
  const HISTORIC_YEARS = Array.from(Array(4).keys()).map((i) => i);

  return (
    <Modal show={true} onClose={onClose} className="md:w-[80%] w-[90%] max-h-[95%] p-12">
      <h3 className="text-2xl text-center font-semibold mb-8">Méthode valeur de productivité</h3>

      <form
        className="w-full p-4"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(values);
          onClose();
        }}>
        <button onClick={() => setShowCompany(!showCompany)} className="w-full transparent-button flex justify-between">
          <h1 className="text-xl font-semibold">Informations financières</h1>

          <span>{showCompany ? "▲" : "▼"}</span>
        </button>

        {showCompany && (
          <table className="w-full table-fixed">
            <thead>
              <tr>
                <th />
                {HISTORIC_YEARS.map((year) => (
                  <th key={year} className="text-right">
                    {STARTING_YEAR - 4 + year}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Résultat d'exploitation</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`operating_income_${i}`]}
                      onValueChange={(_, name, value) => setValues({ ...values, [`operating_income_${i}`]: value.float })}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Impôts sur les sociétés</td>
                {HISTORIC_YEARS.map((_, i) => (
                  <td key={i} className="text-right">
                    {valuation.details.societyTaxes[i].toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Résultat d'exploitation moins impôts</td>
                {HISTORIC_YEARS.map((_, i) => (
                  <td key={i} className="text-right">
                    {valuation.details.operatingProfits[i].toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Pondération des années</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <input
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      type="number"
                      value={values[`ebitda_weighting_${i}`]}
                      onChange={(e) => setValues({ ...values, [`ebitda_weighting_${i}`]: Number(e.target.value) })}
                    />
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        )}

        <button onClick={() => setShowParams(!showParams)} className="w-full transparent-button flex justify-between">
          <h1 className="text-xl font-semibold">Paramètres</h1>

          <span>{showParams ? "▲" : "▼"}</span>
        </button>
        {showParams && (
          <table className="w-1/2 table-fixed">
            <tbody>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Taux de l'IS</td>

                <td className="text-right">
                  <input
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.taxes_rate}
                    onChange={(e) => setValues({ ...values, taxes_rate: Number(e.target.value) })}
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Taux utilisé</td>

                <td className="text-right">
                  <select
                    className="w-full h-full bg-transparent border-none text-right focus:outline-none"
                    value={values.productivity_used_rate}
                    onChange={(e) => setValues({ ...values, productivity_used_rate: e.target.value })}>
                    <option value="wacc">WACC</option>
                    <option value="oat_rate_and_risk_premium">Taux OAT + Prime de risque</option>
                  </select>
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Taux d'investissement sans risque</td>
                <td className="text-right">
                  <input
                    type="number"
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.productivity_investments_rate}
                    onChange={(e) => setValues({ ...values, productivity_investments_rate: Number(e.target.value) })}
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Prime de risque</td>
                <td className="text-right">
                  <input
                    type="number"
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.productivity_risk_prime}
                    onChange={(e) => setValues({ ...values, productivity_risk_prime: Number(e.target.value) })}
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">WACC</td>
                <td className="text-right">
                  <input
                    type="number"
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.productivity_wacc}
                    onChange={(e) => setValues({ ...values, productivity_wacc: Number(e.target.value) })}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        )}

        <button onClick={() => setShowDetails(!showDetails)} className="w-full transparent-button flex justify-between">
          <h1 className="text-xl font-semibold">Détails de la valorisation</h1>

          <span>{showDetails ? "▲" : "▼"}</span>
        </button>
        {showDetails && (
          <table className="w-full table-fixed">
            <tbody>
              <tr className="border-y border-secondary">
                <td className="text-sm font-semibold">REx moyen pondéré</td>
                <td className="text-right">{valuation.details.operatingProfitsAverage.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
              </tr>
            </tbody>
          </table>
        )}

        <div className="w-full mt-4 flex justify-center">
          <div className="w-1/2 p-4 border border-secondary rounded-lg flex flex-col items-center gap-4">
            <h2 className="text-xl text-center font-semibold">Résultat</h2>
            <p className="text-lg text-center font-semibold">{valuation.result.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</p>
            <button className="primary-button" type="submit">
              Valider
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export const NetIncomeAverageModal = ({ company, onSubmit, onClose }) => {
  const [values, setValues] = useState({ ...company });
  const [valuation, setValuation] = useState(netIncomeAverage(company));
  const [showCompany, setShowCompany] = useState(true);
  const [showParams, setShowParams] = useState(true);
  const [showDetails, setShowDetails] = useState(true);

  useEffect(() => {
    setValues(company);
  }, [company]);

  useEffect(() => {
    setValuation(netIncomeAverage(values));
  }, [values]);

  const STARTING_YEAR = values.year_4 || new Date().getFullYear();
  const HISTORIC_YEARS = Array.from(Array(4).keys()).map((i) => i);

  return (
    <Modal show={true} onClose={onClose} className="md:w-[80%] w-[90%] max-h-[95%] p-12">
      <h3 className="text-2xl text-center font-semibold mb-8">Méthode capitalisation du bénéfice net moyen</h3>
      <form
        className="w-full p-4"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(values);
          onClose();
        }}>
        <button onClick={() => setShowCompany(!showCompany)} className="w-full transparent-button flex justify-between">
          <h1 className="text-xl font-semibold">Informations financières</h1>

          <span>{showCompany ? "▲" : "▼"}</span>
        </button>

        {showCompany && (
          <table className="w-full table-fixed">
            <thead>
              <tr>
                <th />
                {HISTORIC_YEARS.map((year) => (
                  <th key={year} className="text-right">
                    {STARTING_YEAR - 4 + year}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Bénéfice net</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`net_income_${i}`]}
                      onValueChange={(_, name, value) => setValues({ ...values, [`net_income_${i}`]: value.float })}
                    />
                  </td>
                ))}
              </tr>

              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Pondération des années</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <input
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      type="number"
                      value={values[`net_income_weighting_${i}`]}
                      onChange={(e) => setValues({ ...values, [`net_income_weighting_${i}`]: Number(e.target.value) })}
                    />
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        )}

        <button onClick={() => setShowParams(!showParams)} className="w-full transparent-button flex justify-between">
          <h1 className="text-xl font-semibold">Paramètres</h1>

          <span>{showParams ? "▲" : "▼"}</span>
        </button>
        {showParams && (
          <table className="w-1/2 table-fixed">
            <tbody>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Nombre d'années</td>

                <td className="text-right">
                  <input
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.net_income_year}
                    onChange={(e) => setValues({ ...values, net_income_year: Number(e.target.value) })}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        )}

        <button onClick={() => setShowDetails(!showDetails)} className="w-full transparent-button flex justify-between">
          <h1 className="text-xl font-semibold">Détails de la valorisation</h1>

          <span>{showDetails ? "▲" : "▼"}</span>
        </button>
        {showDetails && (
          <table className="w-full table-fixed">
            <tbody>
              <tr className="border-y border-secondary">
                <td className="text-sm font-semibold">Bénéfice net moyen pondéré</td>
                <td className="text-right">{valuation.details.netIncomeAverage.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
              </tr>
            </tbody>
          </table>
        )}

        <div className="w-full mt-4 flex justify-center">
          <div className="w-1/2 p-4 border border-secondary rounded-lg flex flex-col items-center gap-4">
            <h2 className="text-xl text-center font-semibold">Résultat</h2>
            <p className="text-lg text-center font-semibold">{valuation.result.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</p>
            <button className="primary-button" type="submit">
              Valider
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export const CashFlowAndCAFModal = ({ company, onSubmit, onClose }) => {
  const [values, setValues] = useState({ ...company });
  const [valuation, setValuation] = useState(cashFlowAndCAF(company));
  const [showCompany, setShowCompany] = useState(true);
  const [showParams, setShowParams] = useState(true);
  const [showDetails, setShowDetails] = useState(true);

  useEffect(() => {
    setValues(company);
  }, [company]);

  useEffect(() => {
    setValuation(cashFlowAndCAF(values));
  }, [values]);

  const STARTING_YEAR = values.year_4 || new Date().getFullYear();
  const HISTORIC_YEARS = Array.from(Array(4).keys()).map((i) => i);

  const SECTORS = [
    "Biens de consommation",
    "Biens d’équipement",
    "Distribution",
    "Energie / Service publics",
    "Immobilier",
    "Industrie automobile",
    "Matériaux",
    "Média / Communication",
    "Santé / médical",
    "Services aux consommateurs",
    "Services aux entreprises",
    "Technologies de l’information",
    "Télécommunication",
    "Transport & logistique",
    "Services financiers",
  ];

  return (
    <Modal show={true} onClose={onClose} className="md:w-[80%] w-[90%] max-h-[95%] p-12">
      <h3 className="text-2xl text-center font-semibold mb-8">Méthode capitalisation de la CAF + trésorerie</h3>
      <form
        className="w-full p-4"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(values);
          onClose();
        }}>
        <button onClick={() => setShowCompany(!showCompany)} className="w-full transparent-button flex justify-between">
          <h1 className="text-xl font-semibold">Informations financières</h1>

          <span>{showCompany ? "▲" : "▼"}</span>
        </button>

        {showCompany && (
          <table className="w-full table-fixed">
            <thead>
              <tr>
                <th />
                {HISTORIC_YEARS.map((year) => (
                  <th key={year} className="text-right">
                    {STARTING_YEAR - 4 + year}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Trésorie</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`cash_flow_${i}`]}
                      onValueChange={(_, name, value) => setValues({ ...values, [`cash_flow_${i}`]: value.float })}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Dette financière</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`debt_${i}`]}
                      onValueChange={(_, name, value) => setValues({ ...values, [`debt_${i}`]: value.float })}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Bénéfice net</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`net_income_${i}`]}
                      onValueChange={(_, name, value) => setValues({ ...values, [`net_income_${i}`]: value.float })}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Dotations aux amortissements</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i} className="text-right">
                    {valuation.details.dnas[i].toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Provisions pour dépréciation d'actifs immobilisés</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i} className="text-right">
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`provisions_fixed_assets_${i}`]}
                      onValueChange={(_, name, value) => setValues({ ...values, [`provisions_fixed_assets_${i}`]: value.float })}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">MBA</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i} className="text-right">
                    {valuation.details.mbas[i].toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Trésorerie</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`treasury_${i}`]}
                      onValueChange={(_, name, value) => {
                        setValues({ ...values, [`treasury_${i}`]: value.float });
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Pondération des années</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <input
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      type="number"
                      value={values[`caf_weighting_${i}`]}
                      onChange={(e) => setValues({ ...values, [`caf_weighting_${i}`]: Number(e.target.value) })}
                    />
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        )}

        <button onClick={() => setShowParams(!showParams)} className="w-full transparent-button flex justify-between">
          <h1 className="text-xl font-semibold">Paramètres</h1>

          <span>{showParams ? "▲" : "▼"}</span>
        </button>
        {showParams && (
          <table className="w-2/3 table-fixed">
            <tbody>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Besoin en Investissements</td>
                <td className="text-right">
                  <select
                    className="w-full h-full bg-transparent border-none text-right focus:outline-none"
                    value={values.caf_investment_need}
                    onChange={(e) => setValues({ ...values, caf_investment_need: e.target.value })}>
                    <option value="Faible">Faible</option>
                    <option value="Normal">Normal</option>
                    <option value="Fort">Fort</option>
                  </select>
                </td>
                <td className="text-right">
                  <input
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.caf_investment_need_factor}
                    onChange={(e) => setValues({ ...values, caf_investment_need_factor: Number(e.target.value) })}
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Secteur d'activité</td>
                <td className="text-right">
                  <select
                    className="w-full h-full bg-transparent border-none text-right focus:outline-none"
                    value={values.caf_sector}
                    onChange={(e) => setValues({ ...values, caf_sector: e.target.value })}>
                    {SECTORS.map((sector) => (
                      <option key={sector} value={sector}>
                        {sector}
                      </option>
                    ))}
                  </select>
                </td>
                <td className="text-right">
                  <input
                    type="number"
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.caf_sector_factor}
                    onChange={(e) => setValues({ ...values, caf_sector_factor: Math.round(e.target.value * 1000) / 1000 })}
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Risque (et opportunité)</td>
                <td className="text-right">
                  <select
                    className="w-full h-full bg-transparent border-none text-right focus:outline-none"
                    value={values.caf_risk}
                    onChange={(e) => setValues({ ...values, caf_risk: e.target.value })}>
                    <option value="Faible">Faible</option>
                    <option value="Normal">Normal</option>
                    <option value="Fort">Fort</option>
                  </select>
                </td>
                <td className="text-right">
                  <input
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.caf_risk_factor}
                    onChange={(e) => setValues({ ...values, caf_risk_factor: Number(e.target.value) })}
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Performance historique et perspectives d'avenir</td>
                <td className="text-right">
                  <select
                    className="w-full h-full bg-transparent border-none text-right focus:outline-none"
                    value={values.caf_stability}
                    onChange={(e) => setValues({ ...values, caf_stability: e.target.value })}>
                    <option value="Entreprise peu stable">Entreprise peu stable</option>
                    <option value="Entreprise stable">Entreprise stable</option>
                    <option value="Entreprise très stable">Entreprise très stable</option>
                  </select>
                </td>
                <td className="text-right">
                  <input
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.caf_stability_factor}
                    onChange={(e) => setValues({ ...values, caf_stability_factor: Number(e.target.value) })}
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Coefficient total</td>
                <td className="text-right"> </td>
                <td className="text-right">
                  <input
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    decimalsLimit={3}
                    value={valuation.details.averageFactor}
                    disabled
                  />
                </td>
              </tr>
            </tbody>
          </table>
        )}

        <button onClick={() => setShowDetails(!showDetails)} className="w-full transparent-button flex justify-between">
          <h1 className="text-xl font-semibold">Détails de la valorisation</h1>

          <span>{showDetails ? "▲" : "▼"}</span>
        </button>
        {showDetails && (
          <table className="w-full table-fixed">
            <tbody>
              <tr className="border-y border-secondary">
                <td className="text-sm font-semibold">MBA moyenne pondérée</td>
                <td className="text-right">{valuation.details.mbasAverage.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
              </tr>
            </tbody>
          </table>
        )}

        <div className="w-full mt-4 flex justify-center">
          <div className="w-1/2 p-4 border border-secondary rounded-lg flex flex-col items-center gap-4">
            <h2 className="text-xl text-center font-semibold">Résultat</h2>
            <p className="text-lg text-center font-semibold">{valuation.result.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</p>
            <button className="primary-button" type="submit">
              Valider
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export const MbaAverageModal = ({ company, onSubmit, onClose }) => {
  const [values, setValues] = useState({ ...company });
  const [valuation, setValuation] = useState(mbaAverage(company));
  const [showCompany, setShowCompany] = useState(true);
  const [showParams, setShowParams] = useState(true);
  const [showDetails, setShowDetails] = useState(true);

  useEffect(() => {
    setValues(company);
  }, [company]);

  useEffect(() => {
    setValuation(mbaAverage(values));
  }, [values]);

  const STARTING_YEAR = values.year_4 || new Date().getFullYear();
  const HISTORIC_YEARS = Array.from(Array(4).keys()).map((i) => i);

  return (
    <Modal show={true} onClose={onClose} className="md:w-[80%] w-[90%] max-h-[95%] p-12">
      <h3 className="text-2xl text-center font-semibold mb-8">Méthode de la capitalisation de la MBA moyenne</h3>

      <form
        className="w-full p-4"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(values);
          onClose();
        }}>
        <button onClick={() => setShowCompany(!showCompany)} className="w-full transparent-button flex justify-between">
          <h1 className="text-xl font-semibold">Informations financières</h1>

          <span>{showCompany ? "▲" : "▼"}</span>
        </button>

        {showCompany && (
          <table className="w-full table-fixed">
            <thead>
              <tr>
                <th />
                {HISTORIC_YEARS.map((year) => (
                  <th key={year} className="text-right">
                    {STARTING_YEAR - 4 + year}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Trésorie</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`cash_flow_${i}`]}
                      onValueChange={(_, name, value) => setValues({ ...values, [`cash_flow_${i}`]: value.float })}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Dette financière</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`debt_${i}`]}
                      onValueChange={(_, name, value) => setValues({ ...values, [`debt_${i}`]: value.float })}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Bénéfice net</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`net_income_${i}`]}
                      onValueChange={(_, name, value) => setValues({ ...values, [`net_income_${i}`]: value.float })}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Dotations aux amortissements</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i} className="text-right">
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`dna_${i}`] || valuation.details.dnas[i]}
                      onValueChange={(_, name, value) => setValues({ ...values, [`dna_${i}`]: value.float })}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Provisions pour dépréciation d'actifs immobilisés</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i} className="text-right">
                    <CurrencyInput
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                      decimalsLimit={2}
                      value={values[`provisions_fixed_assets_${i}`]}
                      onValueChange={(_, name, value) => setValues({ ...values, [`provisions_fixed_assets_${i}`]: value.float })}
                    />
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">MBA</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i} className="text-right">
                    {valuation.details.mbas[i].toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
                  </td>
                ))}
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Pondération des années</td>
                {HISTORIC_YEARS.map((i) => (
                  <td key={i}>
                    <input
                      className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                      type="number"
                      value={values[`mba_weighting_${i}`]}
                      onChange={(e) => setValues({ ...values, [`mba_weighting_${i}`]: Number(e.target.value) })}
                    />
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        )}

        <button onClick={() => setShowParams(!showParams)} className="w-full transparent-button flex justify-between">
          <h1 className="text-xl font-semibold">Paramètres</h1>

          <span>{showParams ? "▲" : "▼"}</span>
        </button>
        {showParams && (
          <table className="w-1/2 table-fixed">
            <tbody>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Remboursement des emprunts</td>
                <td className="text-right">
                  <select
                    className="w-full h-full bg-transparent border-none text-right focus:outline-none"
                    value={values.mba_loan}
                    onChange={(e) => setValues({ ...values, mba_loan: e.target.value })}>
                    <option value="Aucun">Aucun</option>
                    <option value="Faible">Faible</option>
                    <option value="Normal">Normal</option>
                    <option value="Fort">Fort</option>
                  </select>
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Payment des dividendes</td>
                <td className="text-right">
                  <select
                    className="w-full h-full bg-transparent border-none text-right focus:outline-none"
                    value={values.mba_dividends}
                    onChange={(e) => setValues({ ...values, mba_dividends: e.target.value })}>
                    <option value="Aucun">Aucun</option>
                    <option value="Faible">Faible</option>
                    <option value="Normal">Normal</option>
                    <option value="Fort">Fort</option>
                  </select>
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Autres engagement financiers</td>
                <td className="text-right">
                  <select
                    className="w-full h-full bg-transparent border-none text-right focus:outline-none"
                    value={values.mba_other}
                    onChange={(e) => setValues({ ...values, mba_other: e.target.value })}>
                    <option value="Aucun">Aucun</option>
                    <option value="Faible">Faible</option>
                    <option value="Normal">Normal</option>
                    <option value="Fort">Fort</option>
                  </select>
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Part de la CAF/MBA disponible</td>
                <td className="text-right">
                  <input
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    decimalsLimit={3}
                    value={valuation.details.averageFactor}
                    disabled
                  />
                </td>
              </tr>
              <tr className="border-y border-secondary">
                <td className="text-xs font-semibold">Nombre d'années de remboursement</td>
                <td className="text-right">
                  <input
                    step="any"
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values.mba_year}
                    onChange={(e) => setValues({ ...values, mba_year: Number(e.target.value) })}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        )}

        <button onClick={() => setShowDetails(!showDetails)} className="w-full transparent-button flex justify-between">
          <h1 className="text-xl font-semibold">Détails de la valorisation</h1>

          <span>{showDetails ? "▲" : "▼"}</span>
        </button>
        {showDetails && (
          <table className="w-full table-fixed">
            <tbody>
              <tr className="border-y border-secondary">
                <td className="text-sm font-semibold">MBA moyenne pondérée</td>
                <td className="text-right">{valuation.details.mbasAverage.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
              </tr>
            </tbody>
          </table>
        )}

        <div className="w-full mt-4 flex justify-center">
          <div className="w-1/2 p-4 border border-secondary rounded-lg flex flex-col items-center gap-4">
            <h2 className="text-xl text-center font-semibold">Résultat</h2>
            <p className="text-lg text-center font-semibold">{valuation.result.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</p>
            <button className="primary-button" type="submit">
              Valider
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

// TODO
export const PatrimonialeModal = ({ company, onSubmit, onClose }) => {
  const [values, setValues] = useState({ ...company });
  const [valuation, setValuation] = useState({ result: company.valuation_patrimonial || 0 });

  useEffect(() => {
    setValues(company);
  }, [company]);

  useEffect(() => {
    setValuation({ result: values.valuation_patrimonial || 0 });
  }, [values]);

  return (
    <Modal show={true} onClose={onClose} className="md:w-[80%] w-[90%] max-h-[95%] p-12">
      <h3 className="text-2xl text-center font-semibold mb-8">Méthode Patrimoniale</h3>
      <form
        className="flex flex-wrap -mx-2 mt-4"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(values);
          onClose();
        }}>
        <div className="flex justify-between items-center w-full">
          <div className="flex flex-col mb-4 w-full px-2 gap-5 items-center">
            <label className=" text-sm font-medium text-gray-700" htmlFor="isFactorPertinent">
              Valorisation patrimoniale
            </label>
            <CurrencyInput
              className="w-fit h-full appearance-none bg-transparent border-none text-right focus:outline-none"
              intlConfig={{ locale: "fr-FR", currency: "EUR" }}
              decimalsLimit={2}
              value={values.valuation_patrimonial}
              onValueChange={(_, name, value) => setValues({ ...values, valuation_patrimonial: value.float })}
            />
          </div>
        </div>

        <div className="w-full mt-4 flex justify-center">
          <div className="w-1/2 p-4 border border-secondary rounded-lg flex flex-col items-center gap-4">
            <h2 className="text-xl text-center font-semibold">Résultat</h2>
            <p className="text-lg text-center font-semibold">{valuation.result.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</p>
            <button className="primary-button" type="submit">
              Valider
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};
